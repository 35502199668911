import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";

import Web3 from "web3";

import ToggleSidebar from "./sideBar";
import { Bridge } from "./Bridge";
import { Swap } from "./Swap";
import { setRefreshRedux } from "../redux/dataSlice";
import { Reward } from "./Reward";

export default function BEP20() {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState();
  const { refreshRedux } = useSelector((state) => state.user.value);
  const provider = window.ethereum;
  const [currentNetwork, setCurrentNetwork] = useState({
    chainId: 2429,
    rpcUrl: "https://rpc.helperscan.com",
  }); // Default network is BSC Mainnet
  const hider = useRef(null);

  useEffect(() => {
    // Function to switch network when component mounts or refreshRedux changes
    switchNetwork(currentNetwork);
  }, [refresh]);

  async function switchNetwork(network) {
    if (provider) {
      const web3 = new Web3(provider);
      const { chainId, rpcUrl } = network;

      try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
      } catch (error) {
        if (error.code === 4902) {
          // Network change was rejected
          console.log("Network change rejected by user.");
        } else if (error.code === -32002) {
          // Network not added to MetaMask, add it
          try {
            await provider.request({
              method: "wallet_addEthereumChain",
              params: [{ chainId: `0x${chainId.toString(16)}`, rpcUrl }],
            });
            // Try switching again after adding the network
            await provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: `0x${chainId.toString(16)}` }],
            });
          } catch (addError) {
            console.error("Error adding network:", addError);
          }
        } else {
          console.error("Error switching network:", error);
        }
      }
      web3.setProvider(new Web3.providers.HttpProvider(rpcUrl));
    }
  }

  function changeTabs(network) {
    // console.log(refreshRedux, "refreshRedux::::");
    dispatch(setRefreshRedux(!refreshRedux));
    setRefresh(!refresh);
    setCurrentNetwork(network); // Update current network based on tab clicked
  }

  return (
    <>
      <div className="main_banner ">
        <ToggleSidebar />

        <ul
          className="nav nav-tabs "
          id="myTab"
          role="tablist"
          style={{
            marginTop: "7rem",
            justifyContent: "center",
          }}
        >
          <li className="nav-item nav-item1" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              onClick={() =>
                changeTabs({
                  chainId: 2429,
                  rpcUrl: "https://rpc.helperscan.com",
                })
              }
            >
              Swap
            </button>
          </li>
          <li className="nav-item nav-item2" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              onClick={() =>
                changeTabs({
                  chainId: 56,
                  rpcUrl: "https://bsc-dataseed.binance.org/",
                })
              }
            >
              Bridge
            </button>
          </li>
          <li className="nav-item nav-item3" role="presentation">
            <button
              className="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact"
              type="button"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              onClick={() =>
                changeTabs({
                  chainId: 2429,
                  rpcUrl: "https://rpc.helperscan.com",
                })
              }
            >
              Rewards
            </button>
          </li>
        </ul>

        <div className="tab-content p-3" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <Swap />
          </div>
          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <Bridge />
          </div>
          <div
            className="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <Reward />
          </div>
        </div>
      </div>
    </>
  );
}
