import { createSlice } from "@reduxjs/toolkit";
const initialValue = {
  walletAddress: "",
  refreshRedux: false,
};
const dataSlice = createSlice({
  name: "dataSlice",
  initialState: {
    value: initialValue,
  },
  reducers: {
    setWalletAddress: (state, action) => {
      state.value.walletAddress = action.payload.walletAddress;
    },
    setRefreshRedux: (state, action) => {
      state.refresh = action.payload;
    },
  },
});

export const { setWalletAddress, setRefreshRedux } = dataSlice.actions;
export default dataSlice.reducer;
