import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaRecycle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineSwapVert } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { FaHistory } from "react-icons/fa";
import { setWalletAddress } from "../redux/dataSlice";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export default function Navbar() {
  const dispatch = useDispatch();
  const [isWalletConnected, setWalletConnected] = useState(false);
  const { walletAddress } = useSelector((state) => state.user.value);



  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or no longer accessible
      console.log('Please connect to MetaMask.');
      // You can dispatch an action here if necessary to handle the situation
    } else if (accounts[0] !== walletAddress) {
      // Account has changed, update the wallet address
      dispatch(setWalletAddress({ walletAddress: accounts[0] }));
    }
  };

  useEffect(() => {
    // Listen for MetaMask account changes
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }
    return () => {
      // Cleanup the listener when component unmounts
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top py-3 custom_nav">
      <div className="container-fluid">
        <div className="row w-100">
          <div className="col">
            {/* Logo on the left */}
            <a className="navbar-brand" href="/">
              <img src="/images/helper-crosschain.png" width="250px" alt="Logo" />
            </a>
          </div>
          <div className="col-auto text-end">
            <div className="offcanvas offcanvas-end text-bg-dark" data-bs-scroll="true" id="navbarSupportedContent">
              <div className="offcanvas-body">
                <div className="justify-content-end">
                  {/* Wallet connection button on the right */}
                  <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
                }) => {
                  if (account && account.address !== "") {
                    dispatch(
                      setWalletAddress({ walletAddress: account.address })
                    );

                    {
                      /* console.log(account, "wallet-btn"); */
                    }
                  }

                  const ready = mounted && authenticationStatus !== "loading";
                  const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                      authenticationStatus === "authenticated");

                  return (
                    <>
                      <div
                        {...(!ready && {
                          "aria-hidden": true,
                          style: {
                            opacity: 0,
                            pointerEvents: "none",
                            userSelect: "none",
                          },
                        })}
                      >
                        {(() => {
                          if (!connected) {
                            return (
                              <button
                                type="button"
                                className="connect-wallet btn-sm"
                                onClick={openConnectModal}
                              >
                                <span>
                                  <img
                                    src="./images/metamask.png"
                                    className="nav-meta-image"
                                    alt=""
                                  />
                                </span>
                                Connect
                              </button>
                            );
                          }

                          return (
                            <button
                              type="button"
                              className="connect-wallet btn-sm "
                              onClick={openAccountModal}
                            >
                              <span>
                                <img
                                  src="./images/metamask.png"
                                  className="nav-image"
                                  alt=""
                                />
                              </span>
                              {
                                walletAddress
                                  ? walletAddress?.slice(0, 5) +
                                    "..." +
                                    walletAddress?.slice(-5) // If walletAddress exists
                                  : "Connect" // If walletAddress doesn't exist
                              }
                            </button>
                          );
                        })()}
                      </div>
                    </>
                  );
                }}
              </ConnectButton.Custom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
