import React, { useEffect, useState } from "react";
import { MdSwapVerticalCircle } from "react-icons/md";
import Web3 from "web3";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setWalletAddress } from "../redux/dataSlice";
import {
  buy_HLPR,
  buy_WHLPR,
  getBalance,
  swapCoin,
  swapToken,
  TokenWhlprBalnce,
} from "../config/preSaleFunction";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export const Swap = () => {
  const dispatch = useDispatch();
  const { walletAddress, refreshRedux } = useSelector(
    (state) => state.user.value
  );

  // console.log(walletAddress, "wallet:::cnjsjnsdd");
  const [selectedValue, setSelectedValue] = useState("HLPR"); // Default value

  const [isWalletConnected, setWalletConnected] = useState(false);
  const [bnbBal, setBnbBal] = useState();
  const [HLPRbALANCE, setHLPRTokenBal] = useState();
  const [amountFrom, setAmountFrom] = useState(0);
  const [amountTo, setAmountTo] = useState(0);
  const [buyNewLoading, setBuyNewLoading] = useState(false);
  const [refresh, setRefresh] = useState("");
  const handleChange = (e) => {
    let { value } = e.target;

    setAmountFrom(value);
    setAmountTo(value);
  };
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleChangeto = (e) => {
    let { value } = e.target;
    console.log(e);
    setAmountFrom(value);
    setAmountTo(value);
  };

  const provider = window.ethereum;
  const web3 = new Web3(provider);

  function swapHplrToWhlpr() {
    if (walletAddress) {
      if (amountTo > 0) {
        if (bnbBal > 0) {
          // if (userTotalBuy <= 500) {
          buy_WHLPR(amountTo, walletAddress, setBuyNewLoading, setRefresh);
          // } else {
          //   toast.error("Swap Limit Exceed");
          // }
        } else {
          toast.error("Insufficient HLPR Balance");
        }
      } else {
        toast.error("Amount Must Be Greater Than 0");
      }
    } else {
      toast.error("Plese Connect wallet ");
    }
  }
  function swapWHplrTohlpr() {
    if (walletAddress) {
      if (amountTo > 0) {
        if (bnbBal > 0) {
          // if (userTotalBuy <= 500) {
          buy_HLPR(amountTo, walletAddress, setBuyNewLoading, setRefresh);
          // } else {
          //   toast.error("Swap Limit Exceed");
          // }
        } else {
          toast.error("Insufficient Bnb Balance");
        }
      } else {
        toast.error("Amount Must Be Greater Than 0");
      }
    } else {
      toast.error("Plese Connect wallet ");
    }
  }

  const connectWallet = async () => {
    if (!walletAddress) {
      // if (typeof window.ethereum !== "undefined") {
      //   try {
      //     const accounts = await window.ethereum.enable();
      //     setWalletConnected(true);

      //     dispatch(setWalletAddress({ walletAddress: accounts[0] }));
      //   } catch (error) {
      //     console.error("Error while connecting to MetaMask:", error);
      //   }
      // } else {
      //   console.error("Please install MetaMask or use a compatible browser.");
      // }
      toast.error("Please Connect Wallet");
    } else if (selectedValue == "HLPR" && walletAddress) {
      swapHplrToWhlpr();
    } else if (selectedValue == "WHLPR" && walletAddress) {
      swapWHplrTohlpr();
    }
  };

  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or no longer accessible
      console.log("Please connect to MetaMask.");
      // You can dispatch an action here if necessary to handle the situation
    } else if (accounts[0] !== walletAddress) {
      // Account has changed, update the wallet address
      dispatch(setWalletAddress({ walletAddress: accounts[0] }));
    }
  };

  useEffect(() => {
    // Listen for MetaMask account changes
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }
    return () => {
      // Cleanup the listener when component unmounts
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, []);
  useEffect(() => {
    if (walletAddress) {
      getBalance(walletAddress).then((res) => {
        console.log(res, "jhcdf");
        setBnbBal(res);
      });
      TokenWhlprBalnce(walletAddress).then((res) => {
        console.log(res, "refe");
        setHLPRTokenBal(res / 1e18);
      });
    }
  }, [walletAddress, refresh]);

  return (
    <div>
      <div className="wallet_box m-auto">
        <div className="card">
          <div className="text-center card-body">
            <div className="">
              <div className="card-swap d-flex justify-content-space-between">
                <div className="pe-4">
                  <p>From</p>
                  <select value={selectedValue} onChange={handleSelectChange}>
                    <option value="HLPR">HLPR</option>
                    <option value="WHLPR">WHLPR</option>
                  </select>
                  {/* <p>(Helper Chain)</p> */}
                </div>
                <div>
                  <p>
                    Balance :{" "}
                    {selectedValue == "HLPR"
                      ? bnbBal
                      : HLPRbALANCE
                      ? HLPRbALANCE
                      : 0}
                  </p>
                  <div className="input-group">
                    <div>
                      <div>
                        <span class="badge m-1">Min </span>
                        <span class="badge m-1">Max </span>
                      </div>
                    </div>
                    <input
                      type="text"
                      placeholder="0"
                      className="form-control"
                      value={amountTo}
                      onChange={(e) => handleChangeto(e)}
                    />
                    <div
                      className="dropdown input-group-text"
                      style={{ display: "flex", gap: 12 }}
                    >
                      <img src="/images/fav.png" alt="" width={"22px"} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-2 text-center pt-3 pb-3">
                <MdSwapVerticalCircle size={45} color="#fff" />
              </div>
              <div className="card-swap">
                <div className="d-flex justify-content-between">
                  <div className="pe-4">
                    <p>To</p> {/* Changed "From" to "To" for clarity */}
                    <select value={selectedValue} onChange={handleSelectChange}>
                      {selectedValue === "HLPR" ? (
                        <>
                          <option value="WHLPR">WHLPR</option>
                          {/* Add other options if needed */}
                        </>
                      ) : (
                        <>
                          <option value="HLPR">HLPR</option>
                          {/* Add other options if needed */}
                        </>
                      )}
                    </select>
                    {/* <p>(Helper Chain)</p> */}
                  </div>
                  <div>
                    <p>
                      {" "}
                      Balance :{" "}
                      {selectedValue == "HLPR"
                        ? HLPRbALANCE
                        : bnbBal
                        ? bnbBal
                        : 0}
                    </p>
                    <div className="input-group">
                      <div>
                        <div>
                          <span class="badge m-1">Min </span>
                          <span class="badge m-1">Max </span>
                        </div>
                      </div>
                      <input
                        type="text"
                        placeholder="0"
                        className="form-control"
                        value={amountTo}
                        onChange={(e) => handleChangeto(e)}
                      />
                      <div
                        className="dropdown input-group-text"
                        style={{ display: "flex", gap: 12 }}
                      >
                        <img src="/images/fav.png" alt="" width={"22px"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="d-grid mb-2 mt-3 ">
              <div className="d-grid mb-2 mt-3 ">
                <button
                  onClick={connectWallet}
                  className="btn mt-3 setConnectColor text-white btn-outline-dark rounded-pill"
                >
                  {walletAddress ? "Swap" : "Connect Wallet"}
                </button>
              </div>
            </div> */}
            <div className="d-grid mb-2 mt-3 ">
              <div className="d-grid mb-2 mt-3 ">
                {/* <button
                  className="btn mt-3 setConnectColor text-white btn-outline-dark rounded-pill"
                  onClick={connectWallet}
                  disabled={buyNewLoading} // Disable button when buyNewLoading is true
                >
                  {buyNewLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </>
                  ) : walletAddress ? (
                    "Confirm Swap"
                  ) : (
                    "Connect Wallet"
                  )}
                </button> */}
                <ConnectButton.Custom>
                  {({
                    account,
                    chain,
                    openAccountModal,
                    openChainModal,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                  }) => {
                    if (account && account.address !== "") {
                      dispatch(
                        setWalletAddress({ walletAddress: account.address })
                      );

                      {
                        /* console.log(account, "wallet-btn"); */
                      }
                    }

                    const ready = mounted && authenticationStatus !== "loading";
                    const connected =
                      ready &&
                      account &&
                      chain &&
                      (!authenticationStatus ||
                        authenticationStatus === "authenticated");

                    return (
                      <>
                        <div
                          {...(!ready && {
                            "aria-hidden": true,
                            style: {
                              opacity: 0,
                              pointerEvents: "none",
                              userSelect: "none",
                            },
                          })}
                        >
                          {(() => {
                            if (!connected) {
                              return (
                                <button
                                  className="btn mt-3 setConnectColor text-white btn-outline-dark rounded-pill w-100"
                                  onClick={openConnectModal}
                                >
                                  Connect
                                </button>
                              );
                            }

                            return (
                              <button
                                className="btn mt-3 setConnectColor text-white btn-outline-dark rounded-pill w-100"
                                onClick={connectWallet}
                                disabled={buyNewLoading} // Disable button when buyNewLoading is true
                              >
                                {buyNewLoading ? (
                                  <>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Loading...</span>
                                  </>
                                ) : (
                                  "Confirm Swap"
                                )}
                              </button>
                            );
                          })()}
                        </div>
                      </>
                    );
                  }}
                </ConnectButton.Custom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
