import React, { useEffect, useState } from "react";
import { FaShareFromSquare } from "react-icons/fa6";
import Web3 from "web3";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setWalletAddress } from "../redux/dataSlice";
import {
  getBalance,
  swapUSDTToken,
  TokenBalnce,
  USDTBalnce,
} from "../config/preSaleFunction";
import ToggleSidebar from "./sideBar";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  buyToken,
  getBNBBalance,
  getHLPRBep20Balance,
  getPrice,
  getUsdtBalance,
} from "../config/web3";

export const BuyCoin = () => {
  const dispatch = useDispatch();
  const { walletAddress, refreshRedux } = useSelector(
    (state) => state.user.value
  );

  // console.log(walletAddress, "wallet:::cnjsjnsdd");
  const [selectedValue, setSelectedValue] = useState("HLPR"); // Default value

  const [isWalletConnected, setWalletConnected] = useState(false);
  const [bnbBal, setBnbBal] = useState(0);
  const [HLPRBEPbALALNCE, setHlprNatBalan] = useState(0);

  const [HLPRbALANCE, setHLPRTokenBal] = useState();
  const [amountFrom, setAmountFrom] = useState(0);
  const [amountTo, setAmountTo] = useState(0);
  const [buyNewLoading, setLoading] = useState();
  const [refresh, setRefresh] = useState("");

  const handleChangeto = (e) => {
    let { value } = e.target;

    setAmountTo(value);
    getPrice().then((res) => {
      setAmountFrom((res * value) / 1e18);
    });
  };

  const provider = window.ethereum;

  function SwapToken() {
    console.log(amountFrom, amountTo);
    if (walletAddress) {
      if (amountTo > 0) {
        if (bnbBal > 0) {
          // if (userTotalBuy <= 500) {
          buyToken(
            walletAddress,
            amountFrom,

            setLoading,
            setRefresh,
            amountTo
          );
          // } else {
          //   toast.error("Swap Limit Exceed");
          // }
        } else {
          toast.error("Insufficient Bnb Balance");
        }
      } else {
        toast.error("Amount Must Be Greater Than 0");
      }
    } else {
      toast.error("Plese Connect wallet ");
    }
  }

  const connectWallet = async () => {
    if (walletAddress) {
      SwapToken();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (walletAddress) {
        try {
          const bnbBalance = await getBNBBalance(walletAddress);

          setBnbBal(bnbBalance?.formatted);

          const hlprNatBalance = await getHLPRBep20Balance(walletAddress);
          setHlprNatBalan(hlprNatBalance?.formatted);

          const usdtBalance = await getUsdtBalance(walletAddress);
          console.log(usdtBalance);
          setHLPRTokenBal(usdtBalance?.formatted);
        } catch (error) {
          console.error("Error fetching balances:", error);
        }
      }

      try {
        const price = await getPrice();
      } catch (error) {
        console.error("Error fetching price:", error);
      }
    };

    fetchData();
  }, [walletAddress, refresh]);

  const BSC_MAINNET = {
    chainId: 56,
    rpcUrl: "https://bsc-dataseed.binance.org/",
  };

  const initializeWeb3 = async (network) => {
    try {
      const provider = window.ethereum;
      if (provider) {
        // Requesting user permission to switch networks (EIP-1193)
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${network.chainId.toString(16)}` }],
        });

        // Creating a new Web3 instance with the updated provider
        const web3Instance = new Web3(provider);

        const currentNetwork = await web3Instance.eth.net.getId();
        console.log("Connected to network:", currentNetwork);
        toast.success("Binance Chain Is Active");
      } else {
        console.error("Please install MetaMask or use a compatible browser.");
      }
    } catch (error) {
      console.error("Error while switching networks:", error);
    }
  };

  useEffect(() => {
    initializeWeb3(BSC_MAINNET);
  }, []);
  return (
    <div>
      <div className="main_banner " style={{ paddingTop: "80px" }}>
        <ToggleSidebar />

        <div className="wallet_box m-auto m-2">
          <div className="card">
            <div className="text-center card-body">
              <div className="card-swap">
                <div className="d-flex justify-content-between">
                  <div className="pe-4">
                    <p>Buy</p>

                    <option value="HLPR">HLPR</option>

                    <p>(BEP20)</p>
                  </div>
                  <div>
                    <p>Balance : {HLPRBEPbALALNCE ? HLPRBEPbALALNCE : 0}</p>
                    <div className="input-group pe-2">
                      <input
                        type="text"
                        placeholder="0"
                        className="form-control"
                        value={amountTo}
                        onChange={(e) => handleChangeto(e)}
                      />

                      <div
                        className="dropdown input-group-text"
                        style={{ display: "flex", gap: 12 }}
                      >
                        <img src="/images/fav.png" alt="" width={"22px"} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-swap">
                  <div className="d-flex justify-content-between">
                    <div className="pe-4">
                      <p>Pay</p>

                      <p>(USDT)</p>
                    </div>
                    <div>
                      <p>Balance : {HLPRbALANCE ? HLPRbALANCE : 0}</p>
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="0"
                          className="form-control"
                          value={amountFrom}
                          readOnly
                        />

                        <div
                          className="dropdown input-group-text"
                          style={{ display: "flex", gap: 12 }}
                        >
                          <img
                            src="https://bscscan.com/token/images/busdt_32.png"
                            alt=""
                            width={"22px"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           

              <div className="d-grid mb-2 mt-1">
                <div className="d-grid mb-2 mt-3 ">
                  {/* <button
                    className="btn mt-3 setConnectColor text-white btn-outline-dark rounded-pill"
                    onClick={connectWallet}
                    disabled={buyNewLoading} // Disable button when buyNewLoading is true
                  >
                    {buyNewLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Loading...</span>
                      </>
                    ) : walletAddress ? (
                      "Buy Helper"
                    ) : (
                      "Connect Wallet"
                    )}
                  </button> */}
                  <ConnectButton.Custom>
                    {({
                      account,
                      chain,
                      openAccountModal,
                      openChainModal,
                      openConnectModal,
                      authenticationStatus,
                      mounted,
                    }) => {
                      if (account && account.address !== "") {
                        dispatch(
                          setWalletAddress({ walletAddress: account.address })
                        );

                        {
                          /* console.log(account, "wallet-btn"); */
                        }
                      }

                      const ready =
                        mounted && authenticationStatus !== "loading";
                      const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus ||
                          authenticationStatus === "authenticated");

                      return (
                        <>
                          <div
                            {...(!ready && {
                              "aria-hidden": true,
                              style: {
                                opacity: 0,
                                pointerEvents: "none",
                                userSelect: "none",
                              },
                            })}
                          >
                            {(() => {
                              if (!connected) {
                                return (
                                  <button
                                    className="btn mt-3 setConnectColor text-white btn-outline-dark rounded-pill w-100"
                                    onClick={openConnectModal}
                                  >
                                    Connect
                                  </button>
                                );
                              }

                              return (
                                <button
                                  className="btn mt-3 setConnectColor text-white btn-outline-dark rounded-pill w-100"
                                  onClick={connectWallet}
                                  disabled={buyNewLoading} // Disable button when buyNewLoading is true
                                >
                                  {buyNewLoading ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </>
                                  ) : (
                                    "Buy Helper"
                                  )}
                                </button>
                              );
                            })()}
                          </div>
                        </>
                      );
                    }}
                  </ConnectButton.Custom>
                </div>
              </div>

              <div className="card-swap mt-5">
                <div className="card-swap text-align-left p-0">
                  <div className="">
                      <p>
                        Contract:{" "}
                        <span className="fw-bolder ps-1 pe-1">
                          {" "}
                          0x501Cb2Cd151 ..... c2053410A912B{" "}
                          <a href="https://bscscan.com/address/0x501Cb2Cd15198A4853bF8944b04c2053410A912B" className="ps-1 pe-2" target="_blank">

                          <FaShareFromSquare color="#067421" />
                          </a>
                        </span>
                      </p>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
