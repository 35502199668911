import axios from "axios"; // Correct import statement

//const Base_url = "http://localhost:8000/api";
const Base_url = "https://helpercrosschain.com/api";
export const getEventDatas = async (page, status) => {
  try {
    const data = await axios.post(`${Base_url}/getEventData`, null, {
      params: {
        page: page,
        status: status,
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching event data:", error);
  }
};
export const getBuyHistory = async (page, address) => {
  try {
    const requestData = {
      page: page,
      address: address,
    };

    const response = await axios.post(`${Base_url}/buyDetails`, requestData);
    return response.data; // Assuming you want to return the data property of the response
  } catch (error) {
    console.error("Error fetching event data:", error);
    throw error; // Rethrow the error to propagate it to the caller if needed
  }
};

export const getAllListByStatus = async (page, status) => {
  try {
    const data = await axios.post(`${Base_url}/getListByStatus`, null, {
      params: {
        page: page,
        status: status,
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching event data:", error);
  }
};
export const getFilterDatas = (formData) => {
  try {
    const data = axios.post(`${Base_url}/filterAddress`, formData);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const updateStatus = (formData) => {
  try {
    const data = axios.put(`${Base_url}/update-status`, formData);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const updateStatusById = (formData) => {
  try {
    const data = axios.put(`${Base_url}/update-status-id`, formData);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const updateStatusByIdTime = (formData) => {
  try {
    const data = axios.put(`${Base_url}/update-status-idTime`, formData);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getUserStats = () => {
  try {
    const data = axios.get(`${Base_url}/stats`);
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const getUserAmount = (fData) => {
  try {
    const data = axios.get(`${Base_url}/userTotalAmount`, fData);
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getFilterData = () => {
  try {
    const data = axios.get(`${Base_url}/getFilterAddress`);
    return data;
  } catch (e) {
    console.log(e);
  }
};
