import React from 'react'

export default function Footerbar() {
  return (
    <section className="footer-bottom">
<div className="container">
  <div className="row text-mob-center">
    <div className="col-lg-8 mb-2">
      <p>
      28 Bolton Street, London, W1J 8BP
      </p>
      Copyright © 2023 Block Height. All Rights Reserved.
      <span>
        <a href="/images/Terms-Condtions.pdf" target="_blank" className="text-white ms-2">
          {" "}
          Terms and Conditions
        </a>
        <a href="/images/privacy-policy.pdf" target="_blank" className="text-white ms-2">
          Privacy
        </a>
        <a href="#" className="text-white ms-2">
          Cookies
        </a>
      </span>
    </div>
    <div className="col-lg-4 mb-2">
      <div className="d-flex gap-2 justify-sm-center ">
        <a href="#">
          <i class="fab fa-instagram fs-3 text-insta" id="insta"></i>
        </a>
        <a href="#">
          <i class="fab fa-twitter  fs-3 text-info"></i>
        </a>
        <a href="#">
          <i class="fab fa-telegram  fs-3 text-info"></i>
        </a>
        <a href="#">
          <i class="fab fa-youtube fs-3 text-danger"></i>
        </a>
      </div>
      <p>community@cricket.events</p>
    </div>
  </div>

  <div className="row text-mob-center">
    <p className="fs-13">
    It's possible that cryptocurrency is uncontrolled where you live. Cryptocurrencies might both increase and decrease in value. Earnings might be subject to capital gains or other taxes depending on where you live.
    </p>
  </div>
</div>
</section>
  )
}
