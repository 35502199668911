import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Landing from "./pages/Landing";
import Howtobuy from "./pages/Howtobuy";
import "@rainbow-me/rainbowkit/styles.css";

import { Toaster } from "react-hot-toast";

import BEP20 from "./pages/bep20Swap";

import Admin from "./component/Admin";
import Bep20Hlpr from "./pages/Bep20Hlpr";
import { store } from "./redux/store";
import { BuyCoin } from "./pages/BuyCoin";
import { BridgeHistory } from "./pages/BridgeHistory";
import {
  connectorsForWallets,
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  configureChains,
  createConfig,

  WagmiConfig,
} from "wagmi";
import { bsc, bscTestnet, mainnet, polygon, polygonMumbai } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { Provider } from "react-redux";
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  rainbowWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
var mPolygonTestnet = {
  id: 2429,
  name: "Helper Chain",
  network: "maticmum",
  nativeCurrency: {
    decimals: 18,
    name: "Helper",
    symbol: "Helper",
  },
  rpcUrls: {
    default: { http: ["https://rpc.helperscan.com"] },
    public: { http: ["https://rpc.helperscan.com"] },
  },
  blockExplorers: {
    etherscan: { name: "Helper", url: "https://helperscan.com/" },
    default: { name: "Helper", url: "https://helperscan.com/" },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 25770160,
    },
  },
  testnet: true,
};
const { chains, publicClient } = configureChains(
  [bsc],
  [publicProvider()],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `${chain.rpcUrls.default.http[0]}`,
      }),
    }),
  ]
);
const projectId = "b640fb2479f1b615d16f8a39653e517a";

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      //coinbaseWallet({ chains, appName: "My RainbowKit App" }),
      trustWallet({ projectId, chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider
            chains={chains}
            modalSize="compact"
            theme={darkTheme()}
          >
            <Routes>
              <Route exact path="/all-tab" element={<BEP20 />} />
              <Route exact path="howtobuy" element={<Howtobuy />} />
              <Route exact path="/Swap_BEP20_To_WHLPR" element={<BEP20 />} />
              {/* <Route exact path="/BscToHlprNative" element={<Bep20Hlpr />} /> */}

              <Route exact path="/helper_to_WHLPR" element={<Landing />} />
              <Route exact path="/admin" element={<Admin />} />
              <Route exact path="/" element={<BuyCoin />} />
              <Route exact path="/bridgeHistory" element={<BridgeHistory />} />
            </Routes>
          </RainbowKitProvider>
        </WagmiConfig>
      </Provider>
      <Toaster
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastOptions={{
          style: {
            background: "#363636",
            color: "#fff",
          },
        }}
      />
    </BrowserRouter>
  );
}

export default App;
