import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { FaRecycle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { sideBar } from "../helper/helperFunction";
import { MdOutlineSwapVert } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { FaHistory } from "react-icons/fa";
import { setWalletAddress } from "../redux/dataSlice";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const ToggleSidebar = () => {
  const dispatch = useDispatch();
  const [isWalletConnected, setWalletConnected] = useState(false);
  const { walletAddress } = useSelector((state) => state.user.value);

  useEffect(() => {
    sideBar();
  }, []);
  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const accounts = await window.ethereum.enable();

        dispatch(setWalletAddress({ walletAddress: accounts[0] }));
      } catch (error) {
        console.error("Error while connecting to MetaMask:", error);
      }
    } else {
      console.error("Please install MetaMask or use a compatible browser.");
    }
  };
  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or no longer accessible
      console.log("Please connect to MetaMask.");
      // You can dispatch an action here if necessary to handle the situation
    } else if (accounts[0] !== walletAddress) {
      // Account has changed, update the wallet address
      dispatch(setWalletAddress({ walletAddress: accounts[0] }));
    }
  };

  useEffect(() => {
    // Listen for MetaMask account changes
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }
    return () => {
      // Cleanup the listener when component unmounts
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div class="header">
            <div class="header_in d-flex  justify-content-between align-items-center">
              <div>
                <button type="button" class="toggle" id="toggle">
                  <span></span>
                </button>
                <Link to="/">
                  <img
                    src="./images/logoWhite.png"
                    className="nav-image"
                    alt=""
                    // width={100}
                  />
                </Link>
              </div>

              {/* <button
                type="button"
                className="connect-wallet mobile-hide"
                onClick={connectWallet}
              >
                <span>
                  <img
                    src="./images/metamask.png"
                    className="nav-image"
                    alt=""
                  />
                </span>
                <span className="">
                  {
                    walletAddress
                      ? walletAddress?.slice(0, 5) +
                        "..." +
                        walletAddress?.slice(-5) // If walletAddress exists
                      : "Connect" // If walletAddress doesn't exist
                  }
                </span>
              </button> */}

              <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
                }) => {
                  if (account && account.address !== "") {
                    dispatch(
                      setWalletAddress({ walletAddress: account.address })
                    );

                    {
                      /* console.log(account, "wallet-btn"); */
                    }
                  }

                  const ready = mounted && authenticationStatus !== "loading";
                  const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                      authenticationStatus === "authenticated");

                  return (
                    <>
                      <div
                        {...(!ready && {
                          "aria-hidden": true,
                          style: {
                            opacity: 0,
                            pointerEvents: "none",
                            userSelect: "none",
                          },
                        })}
                      >
                        {(() => {
                          if (!connected) {
                            return (
                              <button
                                type="button"
                                className="connect-wallet btn-sm"
                                onClick={openConnectModal}
                              >
                                <span>
                                  <img
                                    src="./images/metamask.png"
                                    className="nav-meta-image"
                                    alt=""
                                  />
                                </span>
                                Connect
                              </button>
                            );
                          }

                          return (
                            <button
                              type="button"
                              className="connect-wallet btn-sm "
                              onClick={openAccountModal}
                            >
                              <span>
                                <img
                                  src="./images/metamask.png"
                                  className="nav-image"
                                  alt=""
                                />
                              </span>
                              {
                                walletAddress
                                  ? walletAddress?.slice(0, 5) +
                                    "..." +
                                    walletAddress?.slice(-5) // If walletAddress exists
                                  : "Connect" // If walletAddress doesn't exist
                              }
                            </button>
                          );
                        })()}
                      </div>
                    </>
                  );
                }}
              </ConnectButton.Custom>
            </div>
          </div>
          <div class="sidebar" id="sidebar">
            <ul>
              <li>
                <Link to="/all-tab">
                  {" "}
                  <span className="icons-cont">
                    <MdOutlineSwapVert size={20} />
                  </span>
                  <span className="text-container">Swap</span>
                </Link>
              </li>
              <li>
                <Link to="/all-tab">
                  <span className="icons-cont">
                    <FaRecycle size={20} />
                  </span>
                  <span className="text-container">Bridge</span>
                </Link>
              </li>
              <li>
                <Link to="/all-tab">
                  {" "}
                  <span className="icons-cont">
                    <FaStar size={20} />
                  </span>
                  <span className="text-container">Rewards</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  {" "}
                  <span className="icons-cont">
                    <FaStar size={20} />
                  </span>
                  <span className="text-container">Buy HLPR</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span className="icons-cont">
                    <FaHistory size={20} />
                  </span>
                  <span className="text-container">Swap History</span>
                </Link>
              </li>
              <li>
                <Link to="/bridgeHistory">
                  {" "}
                  <span className="icons-cont">
                    <FaHistory size={20} />
                  </span>
                  <span className="text-container">Bidge History</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  {" "}
                  <span className="icons-cont">
                    <FaHistory size={20} />
                  </span>
                  <span className="text-container">Rewards History</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToggleSidebar;
