import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import { Pagination } from "@mui/material";
import moment from "moment/moment";
import Web3 from "web3";
import toast from "react-hot-toast";
import {
  getAllListByStatus,
  getEventDatas,
  getFilterData,
  getFilterDatas,
  getUserStats,
  updateStatus,
  updateStatusById,
  updateStatusByIdTime,
} from "../config/api";
import logo from "./fav.png";
import { sendCoinHlpr, sendCoinWHlpr } from "../config/preSaleFunction";
import { walletActions } from "viem";
import { useSelector } from "react-redux";
const Admin = () => {
  const { walletAddress, refreshRedux } = useSelector(
    (state) => state.user.value
  );
  const [stats, setStats] = useState("");

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // State for search
  const [refresh, setRefresh] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [totalReject, setTotalReject] = useState(0);
  const [rejectData, setrejectData] = useState([]);
  const [rejectage, setRejectPage] = useState(1);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [successData, setsuccessData] = useState([]);
  const [succcessage, setSuccessPage] = useState(1);
  const [filterData, setFilterData] = useState([]);
  const [totalFilter, setTotalFilter] = useState(0);
  const [fActiveData, setFActivesData] = useState([]);
  const [filterPage, setFilterPage] = useState(1);
  const handleChange = (e, p) => {
    setPage(p);
  };
  const handleRejectChange = (e, p) => {
    setRejectPage(p);
  };
  const handleSuccessChange = (e, p) => {
    setSuccessPage(p);
  };
  const handlefActiveChange = (e, p) => {
    setFilterPage(p);
  };
  const handleSearch = () => {
    if (searchInput?.trim() !== "") {
      // If search input is provided, filter data based on wallet address and status 0
      const searchData = {
        address: searchInput,
        status: 0,
      };
      getFilterDatas(searchData)
        .then((res) => {
          setData(res.data.data);
          setTotal(res.data.data.length); // Set total based on the length of filtered data
        })
        .catch((error) => {
          console.error("Error fetching event data:", error);
        });
    } else {
      // If search input is empty, fetch all data with status 0
      fetchData(page, 0); // Call fetchData with status 0
    }
  };

  const fetchData = (page, status) => {
    getEventDatas(page, status)
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        console.error("Error fetching event data:", error);
      });
  };

  useEffect(() => {
    fetchData(page, 0); // Fetch data with status 0 on initial load

    getFilterData().then((res) => {
      if (res) {
        setFilterData(res?.data?.data);
      }
    });
  }, [page, refresh]);
  const handleRejectSearch = () => {
    if (searchInput?.trim() !== "") {
      // If search input is provided, filter data based on wallet address and status 0
      const searchData = {
        address: searchInput,
        status: 2,
      };
      getFilterDatas(searchData)
        .then((res) => {
          setData(res.data.data);
          setTotal(res.data.data.length); // Set total based on the length of filtered data
        })
        .catch((error) => {
          console.error("Error fetching event data:", error);
        });
    } else {
      // If search input is empty, fetch all data with status 0
      fetchRejectData(rejectage, 2); // Call fetchData with status 0
    }
  };

  const fetchRejectData = (page, status) => {
    getEventDatas(page, status)
      .then((res) => {
        setrejectData(res.data.data);
        setTotalReject(res.data.total);
      })
      .catch((error) => {
        console.error("Error fetching event data:", error);
      });
  };

  useEffect(() => {
    fetchRejectData(rejectage, 2); // Fetch data with status 0 on initial load
  }, [rejectage, refresh]);
  const handleSuccessSearch = () => {
    if (searchInput?.trim() !== "") {
      // If search input is provided, filter data based on wallet address and status 0
      const searchData = {
        address: searchInput,
        status: 1,
      };
      getFilterDatas(searchData)
        .then((res) => {
          setsuccessData(res.data.data);
          setSuccessPage(res.data.data.length); // Set total based on the length of filtered data
        })
        .catch((error) => {
          console.error("Error fetching event data:", error);
        });
    } else {
      // If search input is empty, fetch all data with status 0
      fetchSuccessData(succcessage, 1); // Call fetchData with status 0
    }
  };

  const fetchSuccessData = (page, status) => {
    getAllListByStatus(page, status)
      .then((res) => {
        setsuccessData(res.data.data);
        setTotalSuccess(res.data.total);
      })
      .catch((error) => {
        console.error("Error fetching event data:", error);
      });
  };

  useEffect(() => {
    fetchSuccessData(succcessage, 1); // Fetch data with status 0 on initial load
  }, [succcessage, refresh]);

  const fetchfActiveData = (page, status) => {
    getAllListByStatus(page, status)
      .then((res) => {
        setFActivesData(res.data.data);
        setTotalFilter(res.data.total);
      })
      .catch((error) => {
        console.error("Error fetching event data:", error);
      });
  };

  useEffect(() => {
    fetchfActiveData(filterPage, 3); // Fetch data with status 0 on initial load
  }, [filterPage, refresh]);
  const handleSelectAll = () => {
    if (!selectedTransactions.length) {
      const selectedData = data.map((item) => ({
        _id: item._id,
        walletAddress: item.address,
        amount: item.amount,
        type: item.type,
      }));
      setSelectedTransactions(selectedData);
    } else {
      setSelectedTransactions([]);
    }
  };

  // Function to handle selecting a particular transaction
  const handleSelectTransaction = (index) => {
    const selectedTransaction = {
      _id: data[index]._id,
      walletAddress: data[index].address,
      amount: data[index].amount,
      type: data[index].type,
    };

    const selectedIndex = selectedTransactions.findIndex(
      (item) => item._id === selectedTransaction._id
    );

    let newSelected = [...selectedTransactions];

    if (selectedIndex === -1) {
      newSelected.push(selectedTransaction);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedTransactions(newSelected);
  };

  // const sendIcome = () => {
  //   console.log(selectedTransactions, "selectedTransactions:::");
  //   if (selectedTransactions && Array.isArray(selectedTransactions)) {
  //     // Check if selectedTransactions is an array
  //     const transactionsData = selectedTransactions.map((transaction) => ({
  //       _id: transaction._id,
  //       walletAddress: transaction.walletAddress,
  //       amount: transaction.amount,

  //     }));

  //     // Extract addresses and amounts from transactionsData
  //     const addresses = transactionsData.map(
  //       (transaction) => transaction.walletAddress
  //     );
  //     let amounts = transactionsData.map((transaction) => transaction.amount);
  //     let id = transactionsData.map((transaction) => transaction._id);

  //     // Deduct 35% from each amount
  //     amounts = amounts.map((amount) => amount);

  //     // Convert addresses to lowercase to ensure correct format
  //     const lowercaseAddresses = addresses.map((address) =>
  //       address.toLowerCase()
  //     );

  //    // sendCoinWHlpr(lowercaseAddresses, amounts, id, setRefresh);
  //     // Call sendCoinHlpr function with addresses and amounts
  //    sendCoinHlpr(lowercaseAddresses, amounts, id, setRefresh);
  //   } else {
  //     toast.error("Select User");
  //   }
  // };
  const sendIcome = () => {
    console.log(selectedTransactions, "selectedTransactions:::");
    if (selectedTransactions && Array.isArray(selectedTransactions)) {
      // Check if selectedTransactions is an array
      const transactionsData = selectedTransactions.map((transaction) => ({
        _id: transaction._id,
        walletAddress: transaction.walletAddress,
        amount: transaction.amount,
      }));

      // Extract addresses and amounts from transactionsData
      const addresses = transactionsData.map(
        (transaction) => transaction.walletAddress
      );
      let amounts = transactionsData.map((transaction) => transaction.amount);
      let id = transactionsData.map((transaction) => transaction._id);

      // Deduct 35% from each amount
      amounts = amounts.map((amount) => amount);

      // Convert addresses to lowercase to ensure correct format
      const lowercaseAddresses = addresses.map((address) =>
        address.toLowerCase()
      );

      // Check the type property of each transaction
      const typeCheck = selectedTransactions.some(
        (transaction) => transaction.type === "DepoitBnbCoin"
      );

      // Conditionally call the appropriate function
      if (typeCheck) {
        sendCoinHlpr(lowercaseAddresses, amounts, id, setRefresh);
      } else {
        // Call another function or handle the case where the type is not "DepoitBnbHlper"
        sendCoinWHlpr(lowercaseAddresses, amounts, id, setRefresh);
      }
    } else {
      toast.error("Select User");
    }
  };

  const rejectUser = (wallet) => {
    let fData = {
      objectIds: wallet,
      status: 2,
    };
    updateStatusById(fData).then((res) => {
      if (res.status) {
        toast.success("Reject Successfully");
        setRefresh(true);
      } else {
        toast.error("Something Went Wrong");
      }
    });
  };
  console.log(stats, "stats:::");
  useEffect(() => {
    getUserStats().then((res) => {
      setStats(res?.data);
    });
  }, [refresh]);

  const dData = [
    {
      heading: "Total Users",
      value: stats?.totalUsers ? stats?.totalUsers : 0,
    },

    {
      heading: "Total Transaction WHLPR",
      value: stats?.totalTransactionsCOIN ? stats?.totalTransactionsCOIN : 0,
    },

    {
      heading: "Total Transaction HLPR",
      value: stats?.totalTransactionsBNB ? stats?.totalTransactionsBNB : 0,
    },
  ];

  const ActivateFilterData = (wallet) => {
    let fData = {
      objectIds: wallet,
      status: 3,
    };
    updateStatusByIdTime(fData).then((res) => {
      if (res.status) {
        toast.success("Verify Successfully");
        setRefresh(true);
      } else {
        toast.error("Something Went Wrong");
      }
    });
  };

  return (
    <div>
      <Navbar />
      {walletAddress == 0x80755dbc7ab389cc7c01e8d8536ebc773089f8f4 ? (
        <div className="container">
          <h4 className="text-uppercase text-center  mb-5 family_adlam fw-bold h2 stat-heading">
            Statistics
          </h4>
          <div className="row">
            {dData?.map((data, i) => (
              <>
                <div className="col-md-4 pt-3" key={i + "card"}>
                  <div className="mycards text-center">
                    <div className="card_footer px-3 pt-3">
                      <img
                        src={logo}
                        alt=""
                        width={20}
                        className="dash-nav-logo dash-card-logo pb-3 mt-2 w-25"
                      />
                      <h5 className="dash-card-heading">{data.heading}</h5>
                      <h3 className="text-secondary  fw-bold">{data.value} </h3>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <h4
            className="text-uppercase text-center pt-3 mb-5 family_adlam fw-bold h2"
            style={{ marginTop: "100px" }}
          >
            Swap Active Details
          </h4>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Wallet Address"
                  aria-label="Wallet Address"
                  aria-describedby="button-addon2"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="ms-1 btn btn-outline-primary"
                    type="button"
                    id="button-addon2"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-end">
              <button
                className="btn btn-outline-primary mb-3"
                type="button"
                id="button-addon2"
                onClick={() => sendIcome()}
              >
                Send Income
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      checked={!!selectedTransactions.length}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">User Address</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Type</th>
                  <th scope="col">Timestamp</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data && data?.length > 0 ? (
                  data.map((item, i) => (
                    <tr key={i}>
                      <th scope="row">
                        <input
                          type="checkbox"
                          checked={selectedTransactions.some(
                            (transaction) => transaction._id === item._id
                          )}
                          onChange={() => handleSelectTransaction(i)}
                        />
                      </th>
                      <td>
                        <span className="text-success">
                          <a
                            target="_blank"
                            href={`https://bscscan.com/tx/${item?.txnHash}`}
                          >
                            <i className="fas fa-external-link-alt text-success"></i>
                          </a>
                        </span>
                        <span className="p-1">
                          {item?.txnHash?.slice(0, 8) +
                            "..." +
                            item?.txnHash?.slice(-8)}
                        </span>
                      </td>
                      <td>
                        {item?.address?.slice(0, 8) +
                          "..." +
                          item?.address?.slice(-8)}
                      </td>
                      <td>{item?.amount / 1e18} </td>
                      <td>
                        {" "}
                        <span className="badge bg-success text-white">
                          {item?.type == "DepoitBnbCoin" ? "HLPR" : "WHLPR"}
                        </span>{" "}
                      </td>
                      <td>{moment(Number(item.timestamp)).fromNow()}</td>
                      <td>
                        {" "}
                        <button
                          className="ms-1 btn btn-outline-danger"
                          type="button"
                          id="button-addon2"
                          onClick={() => rejectUser(item?._id)}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center text-dark mt-2 mb-2 fw-bold fs-5"
                    >
                      No Data Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <hr color="#cbb42a" />
            <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
              <div className="dataTables_info"></div>
              <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                id="application-tbl1_paginate"
              >
                <Pagination
                  color="primary"
                  count={Math.ceil(total / 10)}
                  page={page}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <hr color="#cbb42a" />
          <h4
            className="text-uppercase text-center pt-3 mb-5 family_adlam fw-bold h2"
            style={{ marginTop: "100px" }}
          >
            Swap Paid Details
          </h4>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Wallet Address"
                  aria-label="Wallet Address"
                  aria-describedby="button-addon2"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="ms-1 btn btn-outline-primary"
                    type="button"
                    id="button-addon2"
                    onClick={handleSuccessSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">User Address</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Type</th>
                  <th scope="col">Buy at</th>
                  <th scope="col">Paid at</th>
                </tr>
              </thead>
              <tbody>
                {successData && successData?.length > 0 ? (
                  successData.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <span className="text-success">
                          <a
                            target="_blank"
                            href={`https://bscscan.com/tx/${item?.txnHash}`}
                          >
                            <i className="fas fa-external-link-alt text-success"></i>
                          </a>
                        </span>
                        <span className="p-1">
                          {item?.txnHash?.slice(0, 8) +
                            "..." +
                            item?.txnHash?.slice(-8)}
                        </span>
                      </td>
                      <td>
                        {item?.address?.slice(0, 8) +
                          "..." +
                          item?.address?.slice(-8)}
                      </td>
                      <td>{item?.amount / 1e18} </td>
                      <td>
                        {" "}
                        <span className="badge bg-success text-white">
                          {item?.type == "DepoitBnbCoin" ? "HLPR" : "WHLPR"}
                        </span>{" "}
                      </td>

                      <td>{moment(Number(item.timestamp)).fromNow()}</td>
                      <td>{moment(item.updatedAt).fromNow()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center text-dark mt-2 mb-2 fw-bold fs-5"
                    >
                      No Data Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <hr color="#cbb42a" />
            <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
              <div className="dataTables_info"></div>
              <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                id="application-tbl1_paginate"
              >
                <Pagination
                  color="primary"
                  count={Math.ceil(totalSuccess / 10)}
                  page={succcessage}
                  onChange={handleSuccessChange}
                />
              </div>
            </div>
          </div>
          <hr color="#cbb42a" />
          <h4
            className="text-uppercase text-center pt-3 mb-5 family_adlam fw-bold h2"
            style={{ marginTop: "100px" }}
          >
            Reject Details
          </h4>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Wallet Address"
                  aria-label="Wallet Address"
                  aria-describedby="button-addon2"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="ms-1 btn btn-outline-primary"
                    type="button"
                    id="button-addon2"
                    onClick={handleRejectSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">User Address</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Type</th>
                  <th scope="col">Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {rejectData && rejectData?.length > 0 ? (
                  rejectData.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <span className="text-success">
                          <a
                            target="_blank"
                            href={`https://bscscan.com/tx/${item?.txnHash}`}
                          >
                            <i className="fas fa-external-link-alt text-success"></i>
                          </a>
                        </span>
                        <span className="p-1">
                          {item?.txnHash?.slice(0, 8) +
                            "..." +
                            item?.txnHash?.slice(-8)}
                        </span>
                      </td>
                      <td>
                        {item?.address?.slice(0, 8) +
                          "..." +
                          item?.address?.slice(-8)}
                      </td>
                      <td>{item?.amount / 1e18} </td>
                      <td>
                        {" "}
                        <span className="badge bg-success text-white">
                          {item?.type == "DepoitBnbCoin" ? "HPR" : "WWHLPR"}
                        </span>{" "}
                      </td>

                      <td>{moment(Number(item.timestamp)).fromNow()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center text-dark mt-2 mb-2 fw-bold fs-5"
                    >
                      No Data Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <hr color="#cbb42a" />
            <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
              <div className="dataTables_info"></div>
              <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                id="application-tbl1_paginate"
              >
                <Pagination
                  color="primary"
                  count={Math.ceil(totalReject / 10)}
                  page={rejectage}
                  onChange={handleRejectChange}
                />
              </div>
            </div>
          </div>

          <h4
            className="text-uppercase text-center pt-3 mb-5 family_adlam fw-bold h2"
            style={{ marginTop: "100px" }}
          >
            Bridge Presale Details
          </h4>

          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">User Address</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Type</th>
                  <th scope="col">Timestamp</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {filterData && filterData?.length > 0 ? (
                  filterData.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <span className="text-success">
                          <a
                            target="_blank"
                            href={`https://bscscan.com/tx/${item?.txnHash}`}
                          >
                            <i className="fas fa-external-link-alt text-success"></i>
                          </a>
                        </span>
                        <span className="p-1">
                          {item?.txnHash?.slice(0, 8) +
                            "..." +
                            item?.txnHash?.slice(-8)}
                        </span>
                      </td>
                      <td>
                        {item?.address?.slice(0, 8) +
                          "..." +
                          item?.address?.slice(-8)}
                      </td>
                      <td>{item?.amount / 1e18} </td>
                      <td>
                        {" "}
                        <span className="badge bg-success text-white">
                          {item?.type == "DepoitBnbCoin" ? "HLPR" : "WHLPR"}
                        </span>{" "}
                      </td>
                      <td>{moment(Number(item.timestamp)).fromNow()}</td>
                      <td>
                        {" "}
                        <button
                          className="ms-1 btn btn-outline-success"
                          type="button"
                          id="button-addon2"
                          onClick={() => ActivateFilterData(item?._id)}
                        >
                          Active
                        </button>
                        <button
                          className="ms-1 btn btn-outline-danger"
                          type="button"
                          id="button-addon2"
                          onClick={() => rejectUser(item?._id)}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center text-dark mt-2 mb-2 fw-bold fs-5"
                    >
                      No Data Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <h4
            className="text-uppercase text-center pt-3 mb-5 family_adlam fw-bold h2"
            style={{ marginTop: "100px" }}
          >
            Presale Verified
          </h4>
          <div className="row">
            <div className="col-md-6">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Wallet Address"
                  aria-label="Wallet Address"
                  aria-describedby="button-addon2"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    className="ms-1 btn btn-outline-primary"
                    type="button"
                    id="button-addon2"
                    onClick={handleSuccessSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">User Address</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Type</th>
                  <th scope="col">Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {fActiveData && fActiveData?.length > 0 ? (
                  fActiveData.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <span className="text-success">
                          <a
                            target="_blank"
                            href={`https://bscscan.com/tx/${item?.txnHash}`}
                          >
                            <i className="fas fa-external-link-alt text-success"></i>
                          </a>
                        </span>
                        <span className="p-1">
                          {item?.txnHash?.slice(0, 8) +
                            "..." +
                            item?.txnHash?.slice(-8)}
                        </span>
                      </td>
                      <td>
                        {item?.address?.slice(0, 8) +
                          "..." +
                          item?.address?.slice(-8)}
                      </td>
                      <td>{item?.amount / 1e18} </td>
                      <td>
                        {" "}
                        <span className="badge bg-success text-white">
                          {item?.type == "DepoitBnbCoin" ? "HLPR" : "WHLPR"}
                        </span>{" "}
                      </td>

                      <td>{moment(Number(item.timestamp)).fromNow()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center text-dark mt-2 mb-2 fw-bold fs-5"
                    >
                      No Data Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <hr color="#cbb42a" />
            <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
              <div className="dataTables_info"></div>
              <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                id="application-tbl1_paginate"
              >
                <Pagination
                  color="primary"
                  count={Math.ceil(totalFilter / 10)}
                  page={filterPage}
                  onChange={handlefActiveChange}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1 className="text-center" style={{ marginTop: "90px" }}>
          No Page Found
        </h1>
      )}
    </div>
  );
};

export default Admin;
