import React, { useEffect, useState } from "react";
import { MdSwapVerticalCircle } from "react-icons/md";
import Web3 from "web3";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setWalletAddress } from "../redux/dataSlice";
import { getBalance, TokenBalnce, TokenWhlprBalnce } from "../config/preSaleFunction";
import { PRE_SALE_ABI, WHLPR_ADDRESS } from "../config/config";
import Swal from "sweetalert2";

export const Reward = () => {
  const provider = window.ethereum;
  const web3 = new Web3(provider);

  const dispatch = useDispatch();
  const { walletAddress, refreshRedux } = useSelector(
    (state) => state.user.value
  );

  // console.log(walletAddress, "wallet:::cnjsjnsdd");
  const [selectedValue, setSelectedValue] = useState("HLPR"); // Default value
  const WHLPR_contract = new web3.eth.Contract(PRE_SALE_ABI, WHLPR_ADDRESS);

  const [isWalletConnected, setWalletConnected] = useState(false);
  const [bnbBal, setBnbBal] = useState(0);
  const [HLPRbALANCE, setHLPRTokenBal] = useState();

  const [buyNewLoading, setBuyNewLoading] = useState();
  const [refresh, setRefresh] = useState("");
  const [Roi, setRoi] = useState(1);

  const connectWallet = async () => {
    if (!walletAddress) {
      if (typeof window.ethereum !== "undefined") {
        try {
          const accounts = await window.ethereum.enable();
          setWalletConnected(true);

          dispatch(setWalletAddress({ walletAddress: accounts[0] }));
        } catch (error) {
          console.error("Error while connecting to MetaMask:", error);
        }
      } else {
        console.error("Please install MetaMask or use a compatible browser.");
      }
    } else {
    
      claimROIReward()
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Your Swap Was Successful",
            confirmButtonColor: "#172B48",
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const disconnectWallet = () => {
    setWalletConnected(false);
  };

  useEffect(() => {
    window.ethereum?.on("accountsChanged", disconnectWallet);
    return () => {
      window.ethereum?.removeListener("accountsChanged", disconnectWallet);
    };
  }, [walletAddress]);
  useEffect(() => {
    if (walletAddress) {
      getBalance(walletAddress).then((res) => {
        setBnbBal(res);
      });
      TokenWhlprBalnce(walletAddress).then((res) => {
        console.log(res, "dkdkdkd");
        setHLPRTokenBal(res / 1e18);
      });
    }
  }, [walletAddress, refresh, refreshRedux]);
  async function roiReward() {
    try {
      const data = await WHLPR_contract.methods
        .usersDetail(walletAddress)
        .call();
      console.log(data);
      setRoi(Number(data.totalRewardEarned));
    } catch (error) {
      console.error("Error in roiReward:", error);
    }
  }
  async function claimROIReward() {
    try {
      const claimRes = WHLPR_contract.methods.claimDailyReward();

      const gasEstimate = await claimRes.estimateGas({ from: walletAddress });
      const gasPrice = await web3.eth.getGasPrice();

      await claimRes.send({
        from: walletAddress,
        gas: gasEstimate,
        gasPrice: gasPrice,
      });
    } catch (error) {
      console.error("Error in claimROIReward:", error.message);
      var set = error.message;
      toast.error(set);
      throw error;
    }
  }

  useEffect(() => {
    if (walletAddress != "") {
      roiReward();
    }
  }, [walletAddress]);
             

  return (
    <div>
      <div className="wallet_box m-auto">
        <div className="card">
          <div className=" card-body">
            <div className="form-group">
              <div className="text-white text-left pt-3                                                                                                                                                                                                                                                                                      pb-4">User Address</div>
              <input
                type="text"
                class="form-control"
                readonly
                value={walletAddress}
              />
              <div className="text-white text-left pt-3                                                                                                                                                                                                                                                                                      pb-4">
                Total Balance (WHLPR)
              </div>
              <input
                type="text"
                class="form-control"
                readonly
                value={HLPRbALANCE ? HLPRbALANCE : 0}
              />
              <div className="text-white text-left pt-3                                                                                                                                                                                                                                                                                      pb-4">
                Total Rewards (WHLPR)
              </div>
              <input
                type="text"
                class="form-control"
                readonly
                value={walletAddress?Roi / 1e18:0}
              />
            </div>

            <div className="d-grid mb-2 mt-3 ">
              <div className="d-grid mb-2 mt-3 ">
                <button
                  className="btn mt-3 setConnectColor text-white btn-outline-dark rounded-pill"
                  onClick={connectWallet}
                  disabled={buyNewLoading} // Disable button when buyNewLoading is true
                >
                  {buyNewLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </>
                  ) : walletAddress ? (
                    "Claim Rewards"
                  ) : (
                    "Connect Wallet"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
