import {
  bep20_abi,
  bep20_address,
  BuyHelper,
  BuyHelper_Abi,
  Usdt_Abi,
  Usdt_Address,
} from "./config";
import {
  readContract,
  writeContract,
  fetchBalance,
  waitForTransaction,
} from "@wagmi/core";

import { toast } from "react-hot-toast";

const getAddress = {
  address: BuyHelper,
  abi: BuyHelper_Abi,
};


async function waitfunction(hash) {
  try {
    const data = waitForTransaction({
      hash: hash,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUsdtBalance(address) {
  try {
    const balance = await fetchBalance({
      address: address,
      token: Usdt_Address,
    });
    console.log(balance, "balance");
    return balance;
  } catch (err) {
    console.log(err);
  }
}
export async function getHLPRBep20Balance(address) {
  try {
    const balance = await fetchBalance({
      address: address,
      token: bep20_address,
    });
    return balance;
  } catch (err) {
    console.log(err);
  }
}
export async function getBNBBalance(address) {
  try {
    const nativeBalance = await fetchBalance({
      address: address,
    });
    return nativeBalance;
  } catch (err) {
    console.log(err);
  }
}

export async function getPrice() {
  try {
    let phaseStatus = await readContract({
      ...getAddress,
      functionName: "oneHelperPriceInUsdt",
    });

    let pStatus = Number(phaseStatus);

    return pStatus;
  } catch (error) {
    console.error("Error fetching active fix deposits:", error);
  }
}

async function swapUsdtToHlpr(walletAddress, amount) {
  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getAddress,
    functionName: "buyHelperWithUSDT",
    args: [amount],
    overrides: {
      from: walletAddress,
      value: 0,
    },
  });
  return res;
}

export async function buyToken(
  walletAddress,
  amount,
  setLoading,
  setRefresh,

  amountFrom
) {
  try {
    setLoading(true);
    const getBalance = await getUsdtBalance(walletAddress);
    console.log(getBalance, "getBalance   2");

    const res = await readContract({
      address: Usdt_Address,
      abi: Usdt_Abi,
      functionName: "allowance",
      args: [walletAddress, BuyHelper],
    });

    const allowance = Number(res);
    console.log(allowance, "allowance   3");

    const bal = Number(getBalance?.formatted)?.toFixed(2);
    const allowanceval = Number(amount) * 1e18;
    console.log(bal, allowanceval, "allowance   4");

    //bal >= allowanceval / 1e18
    if (bal >= allowanceval / 1e18) {
      console.log("first If");
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        console.log("first teow");
        let mainamt = (amountFrom * 1e18).toLocaleString("fullwide", {
          useGrouping: false,
        });

        const res = await swapUsdtToHlpr(walletAddress, mainamt);

        const stakeToken = waitfunction(res?.hash);
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              setLoading(false);
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);

            setRefresh(true);
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        console.log("else Infnfnfnf");

        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: Usdt_Address,
          abi: Usdt_Abi,
          functionName: "approve",
          args: [
            BuyHelper,
            allowanceval.toLocaleString("fullwide", { useGrouping: false }),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
          },
        });
        const approve = waitfunction(res?.hash);
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              setLoading(false);
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            let mainamt = (amountFrom * 1e18).toLocaleString("fullwide", {
                useGrouping: false,
              });
      
            const res = await swapUsdtToHlpr(walletAddress, mainamt);
            const stakeToken = waitfunction(res?.hash);
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::");
              });
          });
        // .catch((error) => setLoading(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}
