import React, { useEffect, useState } from "react";
import { MdSwapVerticalCircle } from "react-icons/md";
import Web3 from "web3";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setWalletAddress } from "../redux/dataSlice";
import {
  getBalance,
  TokenBalnce,
  TokenWhlprBalnce,
} from "../config/preSaleFunction";
import { PRE_SALE_ABI, WHLPR_ADDRESS } from "../config/config";
import Swal from "sweetalert2";
import ToggleSidebar from "./sideBar";
import { Pagination } from "@mui/material";
import { getBuyHistory } from "../config/api";
import moment from "moment";

export const BridgeHistory = () => {
  const provider = window.ethereum;
  const web3 = new Web3(provider);

  const dispatch = useDispatch();
  const { walletAddress, refreshRedux } = useSelector(
    (state) => state.user.value
  );

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const handleChange = (e, p) => {
    setPage(p);
  };
  const fetchData = (page, status) => {
    getBuyHistory(page, status)
      .then((res) => {
        console.log(res, "res:::");
        setData(res.data);
        setTotal(res.total);
      })
      .catch((error) => {
        console.error("Error fetching event data:", error);
      });
  };

  useEffect(() => {
    fetchData(page, walletAddress); // Fetch data with status 0 on initial load
  }, [page, walletAddress]);

  return (
    <div>
      <div className="main_banner " style={{ paddingTop: "100px" }}>
        <ToggleSidebar />
        <div className="container m-auto" sty>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr className="text-white">
                      <th scope="col">Transaction ID</th>
                      <th scope="col">User Address</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Type</th>
                      <th scope="col">Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length > 0 ? (
                      data.map((item, i) => (
                        <tr key={i} className="text-white">
                          <td>
                            <span className="text-success">
                              <a
                                target="_blank"
                                href={`https://bscscan.com/tx/${item?.txnHash}`}
                              >
                                <i className="fas fa-external-link-alt text-success"></i>
                              </a>
                            </span>
                            <span className="p-1 text-white">
                              {item?.txnHash?.slice(0, 8) +
                                "..." +
                                item?.txnHash?.slice(-8)}
                            </span>
                          </td>
                          <td className="text-white">
                            {item?.address?.slice(0, 8) +
                              "..." +
                              item?.address?.slice(-8)}
                          </td>
                          <td className="text-white">{item?.amount / 1e18} </td>
                          <td className="text-white">
                            {" "}
                            <span className="badge bg-success text-white">
                              {item?.type == "DepoitBnbCoin" ? "HLPR" : "WHLPR"}
                            </span>{" "}
                          </td>
                          <td className="text-white">
                            {moment(Number(item.timestamp)).fromNow()}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={5}
                          className="text-center text-white mt-2 mb-2 fw-bold fs-5"
                        >
                          No Data Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <hr color="#cbb42a" />
                <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
                  <div className="dataTables_info"></div>
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="application-tbl1_paginate"
                  >
                    <Pagination
                      color="secondary"
                      count={Math.ceil(total / 10)}
                      page={page}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
