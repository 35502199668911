import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";

import Navbar from "../component/Navbar";
import { useRef } from "react";

import { cutAfterDecimal } from "../config/helperFunction";
import Web3 from "web3";
import { PRE_SALE_ABI, WHLPR_ADDRESS } from "../config/config";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

export default function Landing() {
  const { wallet } = useSelector((store) => {
    return store.counter;
  });

  const [rpc, setRpc] = useState(0);
  const [isWalletConnected, setWalletConnected] = useState(false);
  const [helperBalance, setHelperBalance] = useState(false);

  const provider = window.ethereum;
  const web3 = new Web3(provider);

  const WHLPR_contract = new web3.eth.Contract(PRE_SALE_ABI, WHLPR_ADDRESS);

  const hider = useRef(null);

  const [amountFrom, setAmountFrom] = useState(0);
  const [amountTo, setAmountTo] = useState(0);
  const [getTokenData, setGetTokenData] = useState({});
  const [priceData, setPriceData] = useState(1);
  const [btnLoader, setBtnLoader] = useState(false);
  const [apiRef, setApiRef] = useState(false);
  const [conAddr , setConAddr ]  = useState("");

  const [connected_to, setConnected_to] = useState(0);
  const [Roi, setRoi] = useState(1);

  const [prime, setPrime] = useState(1);
  const [selectOption, setSelectOption] = useState({
    symbol: "Helper",
    img: "../../images/usdt_logo.svg",
  });
  const [myWalletBalance, setMyWalletBalance] = useState(0);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const roadmap_responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const media_responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleChange = (e) => {
    let { value } = e.target;
    let numValue = (value);

    setAmountFrom(numValue);
    setAmountTo(numValue);
  };
  const handleChangeto = (e) => {
    let { value } = e.target;
    let numValue = (value);

    setAmountFrom(numValue);
    setAmountTo(numValue);
  };

  const [isfinite, setisFinite] = useState(false);

  //   useEffect(() => {
  //     if (wallet.walletAddress) {
  //       fetchTokenData(wallet).then((res) => {
  //         console.log(res, "TOKEN");
  //         setGetTokenData(res);
  //       });
  //       getbuyWithTokenBalance(wallet).then((res) => {
  //         console.log(res, "TOKENBalace");
  //         setMyWalletBalance(res);
  //       });
  //       setAmountTo(0);
  //       setAmountFrom(0);
  //     }
  //   }, [wallet, apiRef, selectOption]);

  //   // const data = (()=>{
  //   //   roiReward().then((ress)=>{
  //   //     console.log(ress);
  //   //   }).catch((errr)=>{
  //   //     console.log(errr);
  //   //   })
  //   // })

  useEffect(()=>{
    roiReward(connected_to).then((res)=>{
      console.log(res, "calll");
      setRoi(res)
    }).catch((err)=>{
      console.log(err, "calll");

    })
  },[wallet])

  // useEffect(()=>{
  //   primeReward(wallet.walletAddress).then((res)=>{
  //     console.log(res, "calll");
  //     setPrime(res)
  //   }).catch((err)=>{
  //     console.log(err, "calll");

  //   })
  // },[wallet])

  async function claimROIReward() {
    try {
      const claimRes = WHLPR_contract.methods.claimDailyReward();

      const gasEstimate = await claimRes.estimateGas({ from: connected_to });
      const gasPrice = await web3.eth.getGasPrice();

      await claimRes.send({
        from: connected_to,
        gas: gasEstimate,
        gasPrice: gasPrice,
      });

  
    } catch (error) {
      console.error("Error in claimROIReward:", error.message);
      var set = error.message;
      toast.error(set);
      throw error;
    }
  }

  async function roiReward() {
    try {
      const data = await WHLPR_contract.methods
        .usersDetail(connected_to)
        .call();
      console.log(data);
      setRoi(Number(data.totalRewardEarned));
    } catch (error) {
      console.error("Error in roiReward:", error);
    }
  }

  useEffect(() => {
    if (connected_to != "") {
      roiReward();
    }
  }, [connected_to]);

  // const Helper_MAINNET = {
  //   chainId: 2427,
  //   rpcUrl: "https://rpc.helperscan.com",
  // };

  // const initializeWeb3 = async (network) => {
  //   try {
  //     const provider = window.ethereum;
  //     if (provider) {
  //       // Requesting user permission to switch networks (EIP-1193)
  //       await provider.request({
  //         method: "wallet_switchEthereumChain",
  //         params: [{ chainId: `0x${network.chainId.toString(16)}` }],
  //       });

  //       // Creating a new Web3 instance with the updated provider
  //       const web3Instance = new Web3(provider);
  //       setRpc(web3Instance);

  //       //   Log the current network
  //       const currentNetwork = await web3Instance.eth.net.getId();
  //       console.log("Connected to network:", currentNetwork);
  //       toast.success("Helper Chain Is Active");
  //     } else {
  //       console.error("Please install MetaMask or use a compatible browser.");
  //     }
  //   } catch (error) {
  //     console.error("Error while switching networks:", error);
  //   }
  // };

  // useEffect(() => {
  //   initializeWeb3(Helper_MAINNET); // uncoment in production
  // }, []);

  const connectWallet = async () => {                       
    if (typeof window.ethereum !== "undefined") {
      try {
        const accounts = await window.ethereum.enable();
        setWalletConnected(true);
        setConnected_to(accounts[0]);
        console.log("Connected to MetaMask. User account:", accounts[0]);
      } catch (error) {
        console.error("Error while connecting to MetaMask:", error);
      }
    } else {
      console.error("Please install MetaMask or use a compatible browser.");
    }
  };
  const disconnectWallet = () => {
    setWalletConnected(false);
  };

  useEffect(() => {
    window.ethereum?.on("accountsChanged", disconnectWallet);
    return () => {
      window.ethereum?.removeListener("accountsChanged", disconnectWallet);
    };
  }, []);

  async function swapToken(value) {
    try {
      const weiValue = web3.utils.toWei(String(value), "ether");
      console.log(weiValue, "MMM", value);

      const result = await WHLPR_contract.methods
        .swapTokenToCoin(weiValue)
        .send({
          from: connected_to,
        });
      toast.success("Transaction Successful");
      return result;
    } catch (error) {
      console.error("Transaction Failed:", error);
      toast.error(error.message);
      throw error;
    }
  }

  async function buy_WHLPR(val) {
    try {
      const weiValue = web3.utils.toWei(String(val), "ether");
      console.log("Wei Value:", weiValue, val);
      const result = await WHLPR_contract.methods.buyWHLPR(weiValue).send({
        from: connected_to,
        value: weiValue, // Use weiValue instead of val
      });
      return result;
    } catch (error) {
      console.error("Transaction Failed:", error);
      toast.error(error.message);
      throw error;
    }
  }

  // useEffect(() => {
  //   connectWallet();
  // }, []);
  useEffect(() => {
    if (typeof connected_to === 'string') {
      // Ensure connected_to is a string before using slice
      setConAddr(connected_to.slice(27));
     
    }
  }, [connected_to]);

    useEffect(() => {
    if (connected_to != "") {
      const balance = web3.eth.getBalance(connected_to).then((res)=>{
         setHelperBalance(Number(res/1e18).toFixed(4));
         console.log(res,"balance");
      }).catch((err)=>{
        console.log(err,"err");
      })
    }
  }, [connected_to]);
  return (
    <>
      <div className="main_banner ">
        <Navbar />
        <div className="text-center  pt-4  pb-2  page_wrapper">
          <img src="/images/fav.png" alt="" width={"100px"} />
        </div>

        <div className="wallet_box m-auto">
          <div className="card">
            <div className="text-center card-body">
              <h2 className=" fw-bold">Swap</h2>
              <p className="text-center mb-3 fw-bold">
                {/* 1 {getTokenData?.symbol} = ${Number(priceData)} */}1
                HelperCoin ={Number(priceData)} WHLPR
              </p>
              <div>
                <div className="text-center pt-3 pb-1 fs-15 border-bottom fw-bold text-gray">
                  Helper :{helperBalance} || WHLPR :00000
                </div>
              </div>
              <div className="row my-3">
                <div className="col-lg-6">
                  <label htmlFor="amount" className="mb-2 fs-14">
                    Amount in <strong> {selectOption?.symbol}</strong>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="0"
                      className="form-control"
                      value={amountTo}
                      onChange={(e) => handleChangeto(e)}
                    />
                    {/* <div
                      className=" fs-6   btn btn-success"
                      style={{ display: "flex", alignItems: "center", gap: 12 }}
                    >
                      Max
                    </div> */}
                    <div
                      className="dropdown input-group-text"
                      style={{ display: "flex", gap: 12 }}
                    >
                      <img src="./images/fav.png" alt="" width={"22px"} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  {" "}
                  <label htmlFor="amount" className="mb-2 fs-14">
                    {/* Amount in <strong>{getTokenData?.symbol}</strong> you */}
                    Amount in <strong>WHLPR</strong>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="0"
                      className="form-control"
                      value={amountFrom}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span className="input-group-text">
                      <img src="/images/logo.svg" alt="" width={"30px"} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-grid mb-2 mt-5">
                <button
                  className="btn btn-outline-dark rounded-pill"
                  onClick={() =>
                    buy_WHLPR(amountTo)
                      .then((r) => {
                        Swal.fire({
                          icon: "success",
                          title: "Your Swap Was Successful",
                          confirmButtonColor: "#172B48",
                        });
                      })
                      .catch((e) => {
                        console.log(e);
                      })
                  }
                >
                  Swap Helper To WHLPR
                </button>
                <button
                  className="btn mt-3 btn-outline-dark rounded-pill"
                  onClick={() =>
                    swapToken(amountFrom)
                      .then((r) => {
                        Swal.fire({
                          icon: "success",
                          title: "Your Swap Was Successful",
                          confirmButtonColor: "#172B48",
                        });
                      })
                      .catch((e) => {
                        console.log(e);
                      })
                  }
                >
                  Swap WHLPR To Helper
                </button>
                {isWalletConnected ? (
                  <button
                    ref={hider}
                    className="btn mt-3 bg-success text-white btn-outline-dark rounded-pill"
                  >
                    connected 0x__{conAddr}
                  </button>
                ) : (
                  <button
                    ref={hider}
                    onClick={connectWallet}
                    className="btn mt-3 setConnectColor text-white btn-outline-dark rounded-pill"
                  >
                    Connect
                  </button>
                )}
              </div>
              <div className="d-grid mb-2 w-100">
                {/* <ConnectWalletButton */}

                {/* /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-5">
        <div className="container text-center">
          <h1 className="my-5">Reports</h1>
          <div className="row">
            <div className="col-sm">
              {" "}
              <div class="card py-2">
                <div class="card-body">
                  <h5 class="card-title">community Reward</h5>
                  <div className="d-flex justify-content-around">
                    {/* <p class="card-text">{prime /1e18} </p>  */}
                    <p class="card-text">{0} </p>

                    {/* to fix  */}
                    {/* <div className="btn btn-success" onClick={(()=>{
                  claimPrimeHolderReward().then((res)=>{
                    Swal.fire({
            icon: 'success',
            title: 'Your Swap Was Successful',
            confirmButtonColor: '#172B48',
            })
              }).catch((err)=>{
                    console.log(err);
                  })
                })}>
                    claim
                </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              {" "}
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"> Holding Reward</h5>
                  <div className="d-flex justify-content-around">
                    <p class="card-text">{Roi/1e18} : Available Amount</p>
                    <div
                      className="btn btn-success"
                       onClick={() => {
                        claimROIReward()
                          .then((res) => {
                            Swal.fire({
                              icon: "success",
                              title: "Your Swap Was Successful",
                              confirmButtonColor: "#172B48",
                            });
                          })
                          .catch((err) => {
                            console.log(err.message);
                          });
                      }}
                    >
                      claim
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col">
            {" "}
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">Some quick example </p>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
