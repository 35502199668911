import Web3 from "web3";

import { toast } from "react-hot-toast";
import {
  bep20_abi,
  bep20_address,
  BuyHelper,
  BuyHelper_Abi,
  New_PRE_SALE_ABI,
  newBscAbi,
  newBscContract,
  newhlprAbi,
  newhlprContract,
  NewWHLPR_ADDRESS,
  PRE_SALE_ABI,
  Usdt_Abi,
  Usdt_Address,
  WHLPR_ADDRESS,
} from "./config";
import { updateStatus, updateStatusById } from "./api";
const web3 = new Web3(Web3.givenProvider);
const contract_instance = new web3.eth.Contract(newBscAbi, newBscContract);
const token_instance = new web3.eth.Contract(bep20_abi, bep20_address);
const hlpr_contract = new web3.eth.Contract(newhlprAbi, newhlprContract);
const WHLPR_contract = new web3.eth.Contract(PRE_SALE_ABI, WHLPR_ADDRESS);
const usdt_Address = new web3.eth.Contract(Usdt_Abi, Usdt_Address);
const buyHelper_Address = new web3.eth.Contract(BuyHelper_Abi, BuyHelper);

const New_WHLPR_contract = new web3.eth.Contract(
  New_PRE_SALE_ABI,
  NewWHLPR_ADDRESS
);

export async function swapToken(amount, address, setBuyNewLoading, setRefresh) {
  console.log(amount, address, "buy:::::");
  try {
    setBuyNewLoading(true);
    token_instance.methods
      .balanceOf(address)
      .call()
      .then((res) => {
        const userBalance = res / 1e18;
        console.log(res, "userBalance");
        if (userBalance >= amount) {
          token_instance.methods
            .allowance(address, contract_instance._address)
            .call()
            .then(async (res) => {
              const allowance = res / 1e18;
              console.log(res, " jncjdedchj");
              if (allowance >= amount) {
                try {
                  let mainamt = (amount * 1e18).toLocaleString("fullwide", {
                    useGrouping: false,
                  });
                  console.log(mainamt, "mainamt");
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await contract_instance.methods
                    .swapToken(mainamt)
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });

                  const data = contract_instance.methods
                    .swapToken(mainamt)
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });

                  toast
                    .promise(data, {
                      loading: <b>Transaction Pending...</b>,
                      success: () => {
                        return <b>Transaction Successful</b>;
                      },
                      error: () => {
                        return <b>Transaction Canceld.</b>;
                      },
                    })
                    .then((d) => {
                      console.log(d, "success::::");
                      setRefresh(true);
                      setBuyNewLoading(false);
                    })
                    .catch((err) => {
                      setBuyNewLoading(false);
                    });
                } catch (error) {
                  setBuyNewLoading(false);
                  console.log(error, "Error");
                }
              } else if (allowance < amount) {
                console.log("6");
                console.log((amount * 1e18).toString(), "amount*1e18 3");

                try {
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await token_instance.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });
                  const approveData = token_instance.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });

                  toast
                    .promise(approveData, {
                      loading: <b>Approval Pending...</b>,
                      success: <b>Approval Successful</b>,
                      error: <b>Approval request failed.</b>,
                    })
                    .then(async () => {
                      let mainamt = (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      });
                      console.log(mainamt, "mainamt2222");

                      const gasPrice = await web3.eth.getGasPrice();
                      const gas = await contract_instance.methods
                        .swapToken(mainamt)
                        .estimateGas({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                        });
                      const data = contract_instance.methods
                        .swapToken(mainamt)
                        .send({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                          gas: gas,
                        });

                      toast
                        .promise(data, {
                          loading: <b> Transaction Pending...</b>,
                          success: () => {
                            return <b>Transaction Successful</b>;
                          },
                          error: () => {
                            return <b>Transaction Canceld.</b>;
                          },
                        })
                        .then((d) => {
                          setBuyNewLoading(false);
                          setRefresh(true);
                        })
                        .catch((error) => {
                          setBuyNewLoading(false);
                        });
                    })
                    .catch((err) => {
                      setBuyNewLoading(false);
                      console.log(err, "Err-4");
                    });
                } catch (error) {
                  setBuyNewLoading(false);
                  console.log(error, "approval Error");
                }
              }
            })
            .catch((err) => {
              setBuyNewLoading(false);
              console.log(err, "Err-2");
            });
        } else {
          setBuyNewLoading(false);
          toast.error("Insufficient Balance !");
        }
      })
      .catch((err) => {
        setBuyNewLoading(false);
        console.log(err, "Err -1");
      });
  } catch (error) {
    setBuyNewLoading(false);
    console.log(error);
  }
}

export function getBalance(userAddress) {
  return new Promise((resolve, reject) => {
    web3.eth
      .getBalance(userAddress)
      .then((d) => {
        resolve(d / 1e18);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function TokenBalnce(userAddress) {
  try {
    const data = await token_instance.methods.balanceOf(userAddress).call();
    return data;
  } catch (error) {
    console.error("Error fetching token balance:");
    // throw error; // Re-throw the error to propagate it to the caller
  }
}

export async function USDTBalnce(userAddress) {
  try {
    const data = await usdt_Address.methods.balanceOf(userAddress).call();
    return data;
  } catch (error) {
    console.error("Error fetching token balance:");
    // throw error; // Re-throw the error to propagate it to the caller
  }
}
export async function swapUSDTToken(
  amount,
  address,
  setBuyNewLoading,
  setRefresh,
  amountTo
) {
  console.log(amount, address, "buy:::::");
  try {
    setBuyNewLoading(true);
    usdt_Address.methods
      .balanceOf(address)
      .call()
      .then((res) => {
        const userBalance = res / 1e18;
        console.log(res, "userBalance");
        if (userBalance >= amount) {
          usdt_Address.methods
            .allowance(address, buyHelper_Address._address)
            .call()
            .then(async (res) => {
              const allowance = res / 1e18;
              if (allowance >= amount) {
                try {
                  let mainamt = (amountTo * 1e18).toLocaleString("fullwide", {
                    useGrouping: false,
                  });
                  console.log(mainamt, "mainamt");
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await buyHelper_Address.methods
                    .buyHelperWithUSDT(mainamt)
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });

                  const data = buyHelper_Address.methods
                    .buyHelperWithUSDT(mainamt)
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });

                  toast
                    .promise(data, {
                      loading: <b>Transaction Pending...</b>,
                      success: () => {
                        return <b>Transaction Successful</b>;
                      },
                      error: () => {
                        return <b>Transaction Canceld.</b>;
                      },
                    })
                    .then((d) => {
                      console.log(d, "success::::");
                      setRefresh(true);
                      setBuyNewLoading(false);
                    })
                    .catch((err) => {
                      setBuyNewLoading(false);
                    });
                } catch (error) {
                  setBuyNewLoading(false);
                  console.log(error, "Error");
                }
              } else if (allowance < amount) {
                console.log("6");
                console.log((amount * 1e18).toString(), "amount*1e18 3");

                try {
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await usdt_Address.methods
                    .approve(
                      buyHelper_Address?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });
                  const approveData = usdt_Address.methods
                    .approve(
                      buyHelper_Address?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });

                  toast
                    .promise(approveData, {
                      loading: <b>Approval Pending...</b>,
                      success: <b>Approval Successful</b>,
                      error: <b>Approval request failed.</b>,
                    })
                    .then(async () => {
                      let mainamt = (amountTo * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      });
                      console.log(mainamt, "mainamt2222");

                      const gasPrice = await web3.eth.getGasPrice();
                      const gas = await buyHelper_Address.methods
                        .buyHelperWithUSDT(mainamt)
                        .estimateGas({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                        });
                      const data = buyHelper_Address.methods
                        .buyHelperWithUSDT(mainamt)
                        .send({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                          gas: gas,
                        });

                      toast
                        .promise(data, {
                          loading: <b> Transaction Pending...</b>,
                          success: () => {
                            return <b>Transaction Successful</b>;
                          },
                          error: () => {
                            return <b>Transaction Canceld.</b>;
                          },
                        })
                        .then((d) => {
                          setBuyNewLoading(false);
                          setRefresh(true);
                        })
                        .catch((error) => {
                          setBuyNewLoading(false);
                        });
                    })
                    .catch((err) => {
                      setBuyNewLoading(false);
                      console.log(err, "Err-4");
                    });
                } catch (error) {
                  setBuyNewLoading(false);
                  console.log(error, "approval Error");
                }
              }
            })
            .catch((err) => {
              setBuyNewLoading(false);
              console.log(err, "Err-2");
            });
        } else {
          setBuyNewLoading(false);
          toast.error("Insufficient Balance !");
        }
      })
      .catch((err) => {
        setBuyNewLoading(false);
        console.log(err, "Err -1");
      });
  } catch (error) {
    setBuyNewLoading(false);
    console.log(error);
  }
}
export async function getPrice() {
  try {
    const data = await buyHelper_Address.methods.oneHelperPriceInUsdt().call();
    return data;
  } catch (error) {
    console.error("Error :");
    throw error; // Re-throw the error to propagate it to the caller
  }
}

export async function sendCoinHlpr(addresses, amounts, id, setRefresh) {
  //console.log(addresses, amounts, id, "addresses, amounts,id");

  const addr = await hlpr_contract.methods.validator().call();
  console.log(addr,"addr")
  try {
    // Convert addresses to lowercase to ensure correct format
    const lowercaseAddresses = addresses.map((address) =>
      address.toLowerCase()
    );

    // Convert amounts to string representations
    const stringAmounts = amounts.map((amount) => amount.toString());
    const addr = await hlpr_contract.methods.validator().call();
    console.log(addr,"addr")
    const data = await hlpr_contract.methods
      .multiSendHlPR(lowercaseAddresses, stringAmounts)
      .send({ from: addr }); // Assuming sender address is the first address in the array

    // Update status
    let fData = {
      objectIds: id,
      status: 1,
    };
    await updateStatusById(fData); // Wait for the updateStatus function to complete

    toast.success(<b>Send Income Successful</b>);
    setRefresh(true);
  } catch (error) {
    console.log(error, "Error");
    // Show error message using toast
    toast.error(<b>Send Income Failed: {error.message}</b>);
  }
}
export async function sendCoinWHlpr(addresses, amounts, id, setRefresh) {
  console.log(addresses, amounts, id, "addresses, amounts,id");
  try {
    // Convert addresses to lowercase to ensure correct format
    const lowercaseAddresses = addresses.map((address) =>
      address.toLowerCase()
    );

    // Convert amounts to string representations
    const stringAmounts = amounts.map((amount) => amount.toString());
    const addr = await hlpr_contract.methods.validator().call();

    const data = await hlpr_contract.methods
      .multiTokenSend(lowercaseAddresses, stringAmounts)
      .send({ from: addr }); // Assuming sender address is the first address in the array

    // Update status
    let fData = {
      objectIds: id,
      status: 1,
    };
    await updateStatusById(fData); // Wait for the updateStatus function to complete

    toast.success(<b>Send Income Successful</b>);
    setRefresh(true);
  } catch (error) {
    console.log(error, "Error");
    // Show error message using toast
    toast.error(<b>Send Income Failed: {error.message}</b>);
  }
}
export async function swapCoin(amount, address, setBuyNewLoading, setRefresh) {
  console.log(amount, address, "buy:::::");
  try {
    setBuyNewLoading(true);
    token_instance.methods
      .balanceOf(address)
      .call()
      .then((res) => {
        const userBalance = res / 1e18;
        console.log(res, "userBalance");
        if (userBalance >= amount) {
          token_instance.methods
            .allowance(address, contract_instance._address)
            .call()
            .then(async (res) => {
              const allowance = res / 1e18;
              console.log(res, " jncjdedchj");
              if (allowance >= amount) {
                try {
                  let mainamt = (amount * 1e18).toLocaleString("fullwide", {
                    useGrouping: false,
                  });
                  console.log(mainamt, "mainamt");
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await contract_instance.methods
                    .swapToCoin(mainamt)
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });

                  const data = contract_instance.methods
                    .swapToCoin(mainamt)
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });

                  toast
                    .promise(data, {
                      loading: <b>Transaction Pending...</b>,
                      success: () => {
                        return <b>Transaction Successful</b>;
                      },
                      error: () => {
                        return <b>Transaction Canceld.</b>;
                      },
                    })
                    .then((d) => {
                      console.log(d, "success::::");
                      setRefresh(true);
                      setBuyNewLoading(false);
                    })
                    .catch((err) => {
                      setBuyNewLoading(false);
                    });
                } catch (error) {
                  setBuyNewLoading(false);
                  console.log(error, "Error");
                }
              } else if (allowance < amount) {
                console.log("6");
                console.log((amount * 1e18).toString(), "amount*1e18 3");

                try {
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await token_instance.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });
                  const approveData = token_instance.methods
                    .approve(
                      contract_instance?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });

                  toast
                    .promise(approveData, {
                      loading: <b>Approval Pending...</b>,
                      success: <b>Approval Successful</b>,
                      error: <b>Approval request failed.</b>,
                    })
                    .then(async () => {
                      let mainamt = (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      });
                      console.log(mainamt, "mainamt2222");

                      const gasPrice = await web3.eth.getGasPrice();
                      const gas = await contract_instance.methods
                        .swapToCoin(mainamt)
                        .estimateGas({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                        });
                      const data = contract_instance.methods
                        .swapToCoin(mainamt)
                        .send({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                          gas: gas,
                        });

                      toast
                        .promise(data, {
                          loading: <b> Transaction Pending...</b>,
                          success: () => {
                            return <b>Transaction Successful</b>;
                          },
                          error: () => {
                            return <b>Transaction Canceld.</b>;
                          },
                        })
                        .then((d) => {
                          setBuyNewLoading(false);
                          setRefresh(true);
                        })
                        .catch((error) => {
                          setBuyNewLoading(false);
                        });
                    })
                    .catch((err) => {
                      setBuyNewLoading(false);
                      console.log(err, "Err-4");
                    });
                } catch (error) {
                  setBuyNewLoading(false);
                  console.log(error, "approval Error");
                }
              }
            })
            .catch((err) => {
              setBuyNewLoading(false);
              console.log(err, "Err-2");
            });
        } else {
          setBuyNewLoading(false);
          toast.error("Insufficient Balance !");
        }
      })
      .catch((err) => {
        setBuyNewLoading(false);
        console.log(err, "Err -1");
      });
  } catch (error) {
    setBuyNewLoading(false);
    console.log(error);
  }
}

export async function buy_WHLPR(value, address, setBuyNewLoading, setRefresh) {
  try {
    console.log(address, value, "buy_WHLPR"); // Log the address and value for debugging

    setBuyNewLoading(true); // Set loading state to true before the transaction

    const weiValue = web3.utils.toWei(String(value), "ether");

    // Send the transaction to buy WHLPR tokens
    const result =  WHLPR_contract.methods.buyWHLPR(weiValue).send({
      from: address,
      value: weiValue, // Use weiValue instead of val
    });
    // Show toast notification to indicate the status of the transaction
    toast.promise(
      result,
      {
        loading: <b>Swap Pending...</b>,
        success: () => {
          setRefresh(true); // Set refresh flag to trigger re-rendering
          setBuyNewLoading(false); // Set loading state to false after the transaction
          return <b>Swap Successful</b>;
        },
        error: <b>Swap Canceled.</b>,
      },
      { toastId: address } // Unique toast ID to avoid duplicate notifications
    );
  } catch (error) {
    console.error("Error buying WHLPR:", error); // Log any errors that occur during the transaction
    setBuyNewLoading(false); // Set loading state to false in case of error
  }
}

export async function TokenWhlprBalnce(userAddress) {
  try {
    const data = await WHLPR_contract.methods.balanceOf(userAddress).call();
    return data;
  } catch (error) {
    console.error("Error fetching token balance:", error);
    //throw error; // Re-throw the error to propagate it to the caller
  }
}


export async function buy_HLPR(amount, address, setBuyNewLoading, setRefresh) {
  console.log(amount, address, "buy:::::");
  try {
    setBuyNewLoading(true);
    WHLPR_contract.methods
      .balanceOf(address)
      .call()
      .then((res) => {
        const userBalance = res / 1e18;
        console.log(res, amount, "userBalance");
        if (userBalance >= amount) {
          WHLPR_contract.methods
            .allowance(address, New_WHLPR_contract._address)
            .call()
            .then(async (res) => {
              const allowance = res / 1e18;
              console.log(allowance, amount," jncjdedchj");
              if (allowance >= amount) {
                try {
                  let mainamt = (amount * 1e18).toLocaleString("fullwide", {
                    useGrouping: false,
                  });
                  console.log(mainamt, "mainamt");
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await New_WHLPR_contract.methods
                    .swapTokenToCoin(mainamt)
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });

                  const data = New_WHLPR_contract.methods
                    .swapTokenToCoin(mainamt)
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });

                  toast
                    .promise(data, {
                      loading: <b>Transaction Pending...</b>,
                      success: () => {
                        return <b>Transaction Successful</b>;
                      },
                      error: () => {
                        return <b>Transaction Canceld.</b>;
                      },
                    })
                    .then((d) => {
                      console.log(d, "success::::");
                      setRefresh(true);
                      setBuyNewLoading(false);
                    })
                    .catch((err) => {
                      setBuyNewLoading(false);
                    });
                } catch (error) {
                  setBuyNewLoading(false);
                  console.log(error, "Error");
                }
              } else if (allowance < amount) {
                console.log("6");
                console.log((amount * 1e18).toString(), "amount*1e18 3");

                try {
                  const gasPrice = await web3.eth.getGasPrice();
                  const gas = await WHLPR_contract.methods
                    .approve(
                      New_WHLPR_contract?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .estimateGas({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                    });
                  const approveData = WHLPR_contract.methods
                    .approve(
                      New_WHLPR_contract?._address,
                      (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      })
                    )
                    .send({
                      from: address,
                      value: 0,
                      gasPrice: gasPrice,
                      gas: gas,
                    });

                  toast
                    .promise(approveData, {
                      loading: <b>Approval Pending...</b>,
                      success: <b>Approval Successful</b>,
                      error: <b>Approval request failed.</b>,
                    })
                    .then(async () => {
                      let mainamt = (amount * 1e18).toLocaleString("fullwide", {
                        useGrouping: false,
                      });
                      console.log(mainamt, "mainamt2222");

                      const gasPrice = await web3.eth.getGasPrice();
                      const gas = await New_WHLPR_contract.methods
                        .swapTokenToCoin(mainamt)
                        .estimateGas({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                        });
                      const data = New_WHLPR_contract.methods
                        .swapTokenToCoin(mainamt)
                        .send({
                          from: address,
                          value: 0,
                          gasPrice: gasPrice,
                          gas: gas,
                        });

                      toast
                        .promise(data, {
                          loading: <b> Transaction Pending...</b>,
                          success: () => {
                            return <b>Transaction Successful</b>;
                          },
                          error: () => {
                            return <b>Transaction Canceld.</b>;
                          },
                        })
                        .then((d) => {
                          setBuyNewLoading(false);
                          setRefresh(true);
                        })
                        .catch((error) => {
                          setBuyNewLoading(false);
                        });
                    })
                    .catch((err) => {
                      setBuyNewLoading(false);
                      console.log(err, "Err-4");
                    });
                } catch (error) {
                  setBuyNewLoading(false);
                  console.log(error, "approval Error");
                }
              }
            })
            .catch((err) => {
              setBuyNewLoading(false);
              console.log(err, "Err-2");
            });
        } else {
          setBuyNewLoading(false);
          toast.error("Insufficient Balance !");
        }
      })
      .catch((err) => {
        setBuyNewLoading(false);
        console.log(err, "Err -1");
      });
  } catch (error) {
    setBuyNewLoading(false);
    console.log(error);
  }
}
