import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Navbar from "../component/Navbar";
import Footerbar from "../component/Footerbar";
import Lottie from "lottie-react";
import manworking from "../component/json_files/man-working.json";
import chainedphone from "../component/json_files/chained-to-phone.json";


const Howtobuy = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="">
          <div className="row">
            <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top py-3 custom_nav">
              <div class="container-fluid">
                <a class="navbar-brand" href="/">
                  <img src="/images/logo.svg" width="60px" />
                </a>
                <div className="m-auto d-md-block d-lg-none">
                  <button className="btn btn-dark rounded-pill">
                    {/* <i className='fa fa-wallet me-2'></i> */}
                    Play Now
                  </button>
                </div>

                <button
                  class="navbar-toggler border-0"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div
                  class="offcanvas offcanvas-end text-bg-dark"
                  data-bs-scroll="true"
                  id="navbarSupportedContent"
                >
                  <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasExampleLabel">
                      <a class="navbar-brand" href="/">
                        <img
                          src="/images/logo.svg"
                          width="60px"
                          className="invert"
                        />
                      </a>
                    </h5>
                    <button
                      type="button"
                      class="btn-close btn-close-white text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="offcanvas-body">
                    <ul
                      class="navbar-nav me-auto mb-2 mb-lg-0 m-auto"
                      id="list-example"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-dismiss="offcanvas"
                          href="https://cricket.events/"
                          target="_blank"
                        >
                          Play To Earn
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-to
                          href="https://linktr.ee/kricketevents"
                          target="_blank"
                          data-bs-dismiss="offcanvas"
                        >
                          Community
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-dismiss="offcanvas"
                          href="/#how_to_buy"
                        >
                          How to Buy
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-dismiss="close"
                          href="/#tokenomics_content"
                        >
                          Tokenomics
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-dismiss="offcanvas"
                          href="/#airdrop_content"
                        >
                          Airdrop
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-dismiss="offcanvas"
                          href="/#media_content"
                        >
                          Media
                        </a>
                      </li>
                    </ul>

                    <div className="d-md-block d-lg-none">
                      {/* <div className="d-grid my-3">
                        <a href="#" className="btn btn-info">
                          {" "}
                          <i className="fa fa-wallet me-1"></i> Connect Wallet
                        </a>
                      </div> */}
                      <div className="d-flex align-items-center gap-2 mt-3 pt-1 justify-content-center">
                        <div>
                          <img
                            src="/images/instagram.png"
                            height="22px"
                            alt="instagram"
                          />
                        </div>
                        <div>
                          <img
                            src="/images/twitter.svg"
                            height="22px"
                            alt="twitter"
                          />
                        </div>
                        <div>
                          <img
                            src="/images/telegram.svg"
                            height="22px"
                            alt="telegram"
                          />
                        </div>

                        <div>
                          <img
                            src="/images/discord.svg"
                            height="22px"
                            alt="discord"
                          />
                        </div>
                        <div>
                          <img
                            src="/images/youtube.svg"
                            height="22px"
                            alt="youtube"
                          />
                        </div>
                        <div>
                          <img src="/images/tiktok.svg" alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="d-none d-lg-block">
                      <button className="btn btn-dark rounded-pill">
                        {/* <i className='fa fa-wallet me-2'></i> */}
                        Play Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          
        </div>
        <section className="spacer page_wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 text-center mt-2">
                <img
                  src="images/logo.svg"
                  alt=""
                  className="img-fluid"
                  width={"100px"}
                />
                <h2 className="heading mb-4">Play Cricket with Crypto</h2>

                <p>
                  {" "}
                  Are you new to purchasing cryptocurrency tokens? Don't worry; we've got everything you need to know to get started with a $CRIC wallet and purchase tokens right here. All you need to participate in our preorder is a digital wallet (web-based or mobile app) and a browser.
                </p>
                <p>
                We advise utilising MetaMask or Trust Wallet to store, transmit, and receive cryptocurrency, but you should conduct your own research before settling on a wallet. You can't go wrong with either of these well-known names in the business.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="spacer">
          <div className="container process_content">
            <div className="col-lg-3 m-auto">
            <Lottie animationData={manworking} loop={true} />
            
            </div>
          <div className="text-center mb-4">
          <h4 className="heading">PC & Laptop Users</h4>
          </div>

            <div>
              <h3 className="fs-18">Get the MetaMask browser extension</h3>
              <ul>
                <li>
                  MetaMask is a self-custody wallet that only you control. Visit
                  the ​MetaMask website and install the extension for your
                  preferred browser. The most common are Chrome and Firefox.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="fs-18">Start a new wallet</h3>
              <ul>
                <li>
                  Click on the ‘Create a Wallet’ button to start a new wallet
                  that you can use on your mobile phone. Set a new password if
                  prompted to do so.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="fs-18">Save your passphrase</h3>
              <ul>
                <li>
                  This step is very important! Write down your 12-word backup
                  passphrase. Then manually type the 12 words in the correct
                  sequence. You’ll need this passphrase to regain access to your
                  wallet on a different device, should you ever lose access.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="fs-18">Use Ethereum, BNB Chain or Polygon</h3>
              <ul>
                <li>
                  Trust Wallet supports transactions on Ethereum or BNB Chain.
                  You can purchase Cricket Wordcup Token ($CRIC) using ETH, BNB or MATIC  as
                  soon as you buy or send some  ETH, BNB or MATIC to your Trust Wallet
                  address.ETH, BNB or MATIC is needed to pay for gas fees for every
                  transaction.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="fs-18">Claiming your $CRIC tokens</h3>
              <ul>
                <li>
                  After buying $CRIC tokens, you can see how much you’ll be able
                  to claim on the website’s home page. After the presale, once
                  claiming is activated, you’ll be able to claim your $CRIC
                  tokens to the same Trust Wallet address you used to
                  contribute.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="fs-18">Add $CRIC to Trust Wallet</h3>
              <ul>
                <li>
                  To view your token balance within Trust Wallet, add the $CRIC
                  token to your wallet. Click on the dotted button in the
                  top-right of the screen, followed by ‘Add Custom Token’. Paste
                  the $CRIC contract address:
                  <span className="text-truncate d-block">
                    0x3fc6cbd9efdb5914b4f241cbd36da5d52c476722
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="spacer">
          <div className="container process_content">
            <div className="col-lg-3 col-md-4 col-sm-4 m-auto">
            <Lottie animationData={chainedphone} loop={true} />
           
            </div>

            <div className="text-center mb-4">
            <h4 className="heading">Phone & Tablet Users</h4>
            </div>

            <div>
              <h3 className="fs-18">Get the Trust Wallet app</h3>
              <ul>
                <li>
                  There are many mobile wallets out there but Trust Wallet is
                  widely recommended. Download the app from your preferred app
                  store depending on your mobile device.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="fs-18">Start a new wallet</h3>
              <ul>
                <li>
                  Click on the ‘Create a Wallet’ button to start a new wallet
                  that you can use on your mobile phone. Set a new password if
                  prompted to do so.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="fs-18">Save your passphrase</h3>
              <ul>
                <li>
                  This step is very important! Write down your 12-word backup
                  passphrase. Then manually type the 12 words in the correct
                  sequence. You’ll need this passphrase to regain access to your
                  wallet on a different device, should you ever lose access.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="fs-18">Use Ethereum, BNB Chain or Polygon</h3>
              <ul>
                <li>
                  Trust Wallet supports transactions on Ethereum or BNB Chain.
                  You can purchase Cricket Wordcup Token ($CRIC) using  ETH, BNB or MATIC as
                  soon as you buy or send some  ETH, BNB or MATIC to your Trust Wallet
                  address.  ETH, BNB or MATIC is needed to pay for gas fees for every
                  transaction.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="fs-18">Claiming your $CRIC tokens</h3>
              <ul>
                <li>
                  After buying $CRIC tokens, you can see how much you’ll be able
                  to claim on the website’s home page. After the presale, once
                  claiming is activated, you’ll be able to claim your $CRIC
                  tokens to the same Trust Wallet address you used to
                  contribute.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="fs-18">Add $CRIC to Trust Wallet</h3>
              <ul>
                <li>
                  To view your token balance within Trust Wallet, add the $CRIC
                  token to your wallet. Click on the dotted button in the
                  top-right of the screen, followed by ‘Add Custom Token’. Paste
                  the $CRIC contract address:
                  <span className="text-truncate d-block">
                  0x3fc6cbd9efdb5914b4f241cbd36da5d52c476722
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="spacer">
          <div className="container">
            <div className="text-center mb-4">
              <h4 className="heading">HOW TO BUY $CRIC TOKENS</h4>
              <div className="col-lg-8 m-auto">
                <h5 className="mt-3 mb-4">
                  You can buy the Cricket Wordcup Token token using the Ethereum
                  network, BNB Chain or Polygon . The guide below is the same for both
                  chains.
                </h5>

                <div className="steps_content">
                  {" "}
                  <img
                    src="/images/process/step1_connect_wallet.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="my-4">
                    {" "}
                    <h5>STEP 1:</h5>
                    <p>
                      Click on ‘Connect Wallet’ to start the purchase process.
                    </p>
                  </div>
                </div>

                <div className="steps_content">
                  {" "}
                  <img
                    src="/images/process/step2_metamsk.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="my-4">
                    {" "}
                    <h5>STEP 2:</h5>
                    <p>
                      Simply connect with MetaMask or choose Wallet Connect,
                      which supports a number of other wallets. MetaMask is
                      tried and tested on desktop. Trust Wallet works well on
                      mobile phones.
                    </p>
                  </div>
                </div>

                <div className="steps_content">
                  {" "}
                  <img
                    src="/images/process/step3.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="my-4">
                    {" "}
                    <h5>STEP 3:</h5>
                    <p>
                      $CRIC tokens can be purchased using ETH, BNB MATIC, or USDT.
                      Select either the Ethereum or BNB Smart Chain network in
                      your wallet. Then choose the corresponding tab to use your
                      preferred currency
                    </p>
                  </div>
                </div>

               <section>
                  <h4 className="text-decoration-underline">
                    BUYING WITH  ETH, BNB or MATIC
                  </h4>
                  <div className="steps_content">
                    {" "}
                    <img
                      src="/images/process/step4_buy_with_bnb.png"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="my-4">
                      {" "}
                      <p>
                        Select the ETH, BNB or MATIC tab and enter the amount of ETH or
                        BNB you wish to swap for $CRIC tokens. Click ‘Buy Now’
                        to proceed.
                      </p>
                    </div>
                  </div>
                </section>

                <div className="steps_content">
                  {" "}
                  <img
                    src="/images/process/step5_confirmation.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="my-4">
                    {" "}
                    <p>
                      Review the transaction details and gas fee when the
                      MetaMask window pops up. Click ‘Confirm’ when you’re happy
                      to proceed.
                    </p>
                  </div>
                </div>
                <div className="steps_content">
                  {" "}
                  <img
                    src="/images/process/success.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="my-4">
                    {" "}
                    <p>
                      After the transaction goes through, you’ll see a
                      confirmation of your successful purchase. You’ll be able
                      to claim your $CRIC tokens to the same wallet when
                      claiming is ready.
                    </p>
                  </div>
                </div> 


 {/* 
                <section>
                  <h4 className="text-decoration-underline">
                    BUYING WITH BANK CARD
                  </h4>
                  <div className="steps_content">
                    {" "}
                    <img
                      src="https://wallstmemes.com/assets/images/how-to-buy/11.svg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="my-4">
                      {" "}
                      <p>
                        Select the CARD tab and enter the amount in USD you wish
                        to swap for $CRIC tokens. Click ‘Buy Now’ to proceed.
                        You’ll need to connect the crypto wallet you want your
                        tokens to be sent to.
                      </p>
                    </div>
                  </div>

                  <div className="steps_content">
                    {" "}
                    <img
                      src="https://wallstmemes.com/assets/images/how-to-buy/12.svg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="my-4">
                      {" "}
                      <p>
                        When the Wert widget pops up, enter your mobile number
                        and verify the code sent. Check the details and fees for
                        your purchase, and confirm the order. The transaction
                        will go through your local bank. Your $CRIC tokens can
                        be claimed to your crypto wallet when claiming is live.
                      </p>
                    </div>
                  </div>

                  <div className="steps_content">
                    {" "}
                    <img
                      src="https://wallstmemes.com/assets/images/how-to-buy/11.svg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="my-4">
                      {" "}
                      <p>
                        Select the CARD tab and enter the amount in USD you wish
                        to swap for $CRIC tokens. Click ‘Buy Now’ to proceed.
                        You’ll need to connect the crypto wallet you want your
                        tokens to be sent to.
                      </p>
                    </div>
                  </div>
                </section>

                <section>
                  <h4 className="text-decoration-underline">
                    BUYING WITHOUT A WALLET
                  </h4>
                  <p>
                    If you don’t have a wallet, select this option when trying
                    to connect to review your options and download a new wallet.
                  </p>
                  <div className="steps_content">
                    {" "}
                    <img
                      src="https://wallstmemes.com/assets/images/how-to-buy/13.svg"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="my-4">
                      {" "}
                      <p>
                        Select ‘I don’t have a wallet’ and you’ll be directed to
                        the official Ethereum website to see your wallet
                        options. For new crypto users, we recommend MetaMask if
                        you use a laptop and Trust Wallet if you prefer using
                        your phone. Remember to also do your own research.
                      </p>
                    </div>
                  </div>
                </section> */}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footerbar />
    </>
  );
};

export default Howtobuy;
